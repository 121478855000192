import React, { useState, useEffect } from "react";
import { Box, Button, List } from "@mui/material";
import ChoiceDialog from "../../../../components/ChoiceDialog";
import {
  EventRecord,
  LSEventRecord,
  jsonobject,
} from "../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../translations/Translations";
import { useParams, useNavigate } from "react-router-dom";
import { formatDateTime, formatDate } from "../../../../helpers/DateFunctions";
import useDebounce from "../../../../helpers/useDebounce";
import { syncRequest } from "./helpers";
import Scanner from "./components/Scanner";
import EventListItemButton from "./components/EventListItemButton";
import SyncModal from "./components/SyncModal";
import "./style.scss";
import TopToolbar from "../../../../components/TopToolbar";
import { Loading } from "./components/Loading";

function ScannerPage() {
  let { eventId } = useParams<string>();
  const translations = getTranslations();
  const navigate = useNavigate();

  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  const [category] = useState<string>("");
  const [pickedDate] = useState<Date | null>(null);
  const [search] = useState<string>("");
  const [rows, setRows] = useState<EventRecord[]>([]);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [showSyncQuestionModal, setShowSyncQuestionModal] =
    useState<boolean>(false);
  const [showSyncCompleted, setShowSyncCompleted] = useState<boolean>(false);
  const [selectedSyncEvent, setSelectedSyncEvent] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showNotOnlineWindow, setShowNotOnlineWindow] =
    useState<boolean>(false);

  const debouncedSearch = useDebounce(search, 500);

  /* Functions */
  const syncEntireEvent = (eventId: number, callback?: Function) => {
    const lSScanData = localStorage.getItem("scanData");
    const parsedLSScandata: LSEventRecord[] = lSScanData
      ? JSON.parse(lSScanData)
      : [];
    const eventIndex = parsedLSScandata.findIndex(
      (LSEvent) => LSEvent.eventId === eventId
    );
    if (eventIndex !== -1) {
      setIsSyncing(true);
      const currentEvent = parsedLSScandata[eventIndex];
      const scanRecords = [];
      for (let i = 0; i < currentEvent.data.length; i++) {
        for (let x = 0; x < currentEvent.data[i].scans.length; x++) {
          if (currentEvent.data[i].scans[x].deleted === false) {
            scanRecords.push({
              publicId: currentEvent.data[i].scans[x].publicId,
              eventId: eventId,
              blockId: currentEvent.data[i].scans[x].blockId,
              activityId: currentEvent.data[i].scans[x].activityId,
              checkIn:
                currentEvent.data[i].scans[x].type === "checkin" ? true : false,
              checkDate: formatDateTime(
                parseInt(currentEvent.data[i].scans[x].date, 10)
              ),
            });
          }
        }
      }

      const parsedTempScanData: LSEventRecord[] = JSON.parse(
        localStorage.getItem("tempScanData") || "null"
      );

      if (parsedTempScanData?.length > 0) {
        const tempEventIndex = parsedTempScanData.findIndex(
          (parsedEvent) => parsedEvent.eventId === eventId
        );

        if (tempEventIndex != -1) {
          const tempEvent = parsedTempScanData[tempEventIndex];
          const hasSessions = tempEvent.data.some((session) => session.session);

          const hasDifferences = hasSessions
            ? tempEvent.data.some((session) => {
                const sessionIndex = currentEvent.data.findIndex(
                  (localEvent) => localEvent.session === session.session
                );
                return (
                  sessionIndex !== -1 &&
                  JSON.stringify(session.scans) !==
                    JSON.stringify(currentEvent.data[sessionIndex].scans)
                );
              })
            : JSON.stringify(tempEvent.data[0].scans) !==
              JSON.stringify(currentEvent.data[0].scans);

          if (!hasDifferences) {
            parsedTempScanData.splice(tempEventIndex, 1);
            localStorage.setItem(
              "tempScanData",
              JSON.stringify(parsedTempScanData)
            );
          }
        }
      }

      syncRequest(scanRecords, () => {
        const newScanData = [...parsedLSScandata];
        newScanData.splice(eventIndex, 1);
        localStorage.setItem("scanData", JSON.stringify(newScanData));

        setIsSyncing(false);
        if (callback) {
          callback();
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  };

  /* END FUNCTIONS */

  /* STATE CONTROL */

  useEffect(() => {
    if (eventId) {
      setSelectedEvent(Number(eventId));
    }
  }, [eventId]);

  //Fix backtaps
  useEffect(() => {
    if (selectedEvent === null && loading === true) {
      setLoading(false);
    }
  }, [selectedEvent]);

  useEffect(() => {
    setShowSyncQuestionModal(typeof selectedSyncEvent !== "undefined");
  }, [selectedSyncEvent]);

  useEffect(() => {
    const controller = new AbortController();
    let url: string = "/api/scanner/events";
    let objlist: jsonobject[] = [];

    if (debouncedSearch !== "") {
      objlist.push({ property: "search", value: debouncedSearch });
    }
    if (category !== "") {
      objlist.push({ property: "category", value: category });
    }
    if (pickedDate !== null) {
      objlist.push({
        property: "startdate",
        value: formatDate(pickedDate) || "",
      });
    }

    if (objlist.length > 0) {
      let params: URLSearchParams = new URLSearchParams({
        filter: JSON.stringify(objlist),
      });
      url = url + "?" + params.toString();
    }

    setLoading(true);

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        setRows(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
          setLoading(false);
        }
      });
    return () => {
      setLoading(false);
      controller.abort();
    };
  }, [debouncedSearch, category, pickedDate]);

  /* END STATE CONTROL */

  return (
    <>
      {selectedEvent ? (
        <Box display="flex" flexDirection="column" flex="1">
          <TopToolbar title={""} />
          <Scanner setSelectedEvent={setSelectedEvent} />
        </Box>
      ) : (
        <Box
          className="scanner"
          display={"flex"}
          flex={1}
          flexDirection={"column"}
          alignItems={"stretch"}
        >
          <ChoiceDialog
            open={[showSyncQuestionModal, setShowSyncQuestionModal]}
            title={translations["scanner.alertSync"]}
            description={translations["scanner.alertSyncOneDescription"]}
            buttons={[
              <Button
                variant="contained"
                onClick={() => {
                  if (selectedSyncEvent) {
                    if (navigator.onLine) {
                      syncEntireEvent(selectedSyncEvent, () => {
                        setShowSyncCompleted(true);
                        setSelectedSyncEvent(undefined);
                      });
                    } else {
                      setShowNotOnlineWindow(true);
                      //Show not online error
                    }
                  }
                }}
              >
                {translations["scanner.sync"]}
              </Button>,
              <Button
                onClick={() => {
                  setSelectedSyncEvent(undefined);
                }}
              >
                {translations["scanner.syncNo"]}
              </Button>,
            ]}
          />
          <SyncModal open={isSyncing} />
          <ChoiceDialog
            open={[showSyncCompleted, setShowSyncCompleted]}
            title={translations["scanner.syncCompletedTitle"]}
            description={translations["scanner.syncCompleted"]}
            buttons={[
              <Button
                onClick={() => {
                  setShowSyncCompleted(false);
                }}
              >
                {translations["confirm"]}
              </Button>,
            ]}
          />
          <ChoiceDialog
            open={[showNotOnlineWindow, setShowNotOnlineWindow]}
            title={translations["scanner.notOnline"]}
            description={translations["scanner.notOnlineDescription"]}
            buttons={[
              <Button
                onClick={() => {
                  setShowNotOnlineWindow(false);
                }}
              >
                {translations["confirm"]}
              </Button>,
            ]}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <TopToolbar title={""} />
            {loading ? <Loading /> : null}
            {!loading && rows && rows.length > 0 ? (
              <List
                className="eventlist"
                sx={{
                  padding: "1rem",
                  paddingTop: 0,
                  flexDirection: "column",
                  flexBasis: 0,
                  flexGrow: 1,
                  overflow: "scroll",
                }}
              >
                {rows &&
                  rows.map((event: EventRecord) => {
                    return (
                      <EventListItemButton
                        key={event.id}
                        eventRecord={event}
                        onListItemClick={() => {
                          setLoading(true);
                          navigate("/scanner/" + event.id, { replace: false });
                        }}
                        onSyncButtonClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          setSelectedSyncEvent(event.id);
                        }}
                      />
                    );
                  })}
              </List>
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
}

export default ScannerPage;

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SessionInfo from "./SessionInfo";
import {
  Filter,
  LocationRecord,
  ModuleRecord,
  SessionRecord,
} from "../../../../../interfaces/Interfaces";
import { ContainedStyledButton } from "./ContainedStyledButton";
import { getTranslations } from "../../../../../translations/Translations";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ClearIcon from "@mui/icons-material/Clear";
import ModuleInfo from "./ModuleInfo";

const SessionSelect = ({
  currentSelectSession,
  sessions,
  modules,
  language,
  currentLocation,
  setSelectedSession,
  setCurrentSelectSession,
  setSelectedScanBlock,
}: {
  sessions: SessionRecord[];
  modules: ModuleRecord[];
  language: string;
  currentLocation: LocationRecord | undefined;
  currentSelectSession: SessionRecord | undefined;
  setCurrentSelectSession: Function;
  setSelectedScanBlock: Function;
  setSelectedSession: Function;
}) => {
  const translations = getTranslations();
  //SelectedBlock is for the filter
  const [selectedBlock, setSelectedBlock] = useState<string>("");

  //SelectedScanBlock to scan blocks instead of activity
  const [currentSelectedScanBlock, setCurrentSelectedScanBlock] =
    useState<ModuleRecord | null>(null);

  const [activityFilters, setActivityFilters] = useState<Filter[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<SessionRecord[]>(
    []
  );
  const [showModuleFilter, setShowModuleFilter] = useState<boolean>(false);

  const filteredModules = modules.filter((module) => module.scannable);

  const blockFilter = (record: any, value: string) => {
    if (value === "") {
      return true;
    } else {
      if (Number(value) < 0) {
        return record.blockId === null;
      } else {
        return record.blockId === Number(value);
      }
    }
  };

  useEffect(() => {
    if (selectedBlock !== null) {
      console.log("selectedBlock", selectedBlock);
      setActivityFilters((prevFilters) => {
        const filterIndex = prevFilters.findIndex(
          (filter) => filter.filterName === "block"
        );
        if (filterIndex !== -1) {
          prevFilters[filterIndex].filterFunction = (row: any) =>
            blockFilter(row, selectedBlock);
          return [...prevFilters];
        } else {
          return [
            ...prevFilters,
            {
              filterName: "block",
              filterFunction: (row: any) => blockFilter(row, selectedBlock),
            },
          ];
        }
      });
    } else {
      setActivityFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.filterName !== "block")
      );
    }
  }, [selectedBlock]);

  useEffect(() => {
    const scannableSessions = [...sessions].filter(
      (session) => session.scannable
    );

    if (activityFilters.length > 0) {
      let filteredRows = [...scannableSessions];
      activityFilters.forEach((myFilter) => {
        filteredRows = filteredRows.filter((record) =>
          myFilter.filterFunction(record)
        );
      });
      setFilteredActivities(filteredRows);
      setCurrentSelectSession(undefined);
    } else {
      setFilteredActivities(scannableSessions);
      setCurrentSelectSession(undefined);
    }
  }, [activityFilters]);

  const hasNoSessionSession = sessions.some((obj) => obj.blockId === null);
  const currentModule =
    currentSelectSession && currentSelectSession.blockId
      ? modules.find((module) => module.id === currentSelectSession.blockId)
      : null;

  return (
    <Box display="flex" flexDirection="column" flex={1} p={"1rem"}>
      {showModuleFilter ? (
        modules.length > 0 ? (
          <Box
            mb={"1rem"}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedBlock("");
                setShowModuleFilter(false);
              }}
            >
              <FilterAltOffIcon />
            </IconButton>
            {translations["scanner.blockFilter"]}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-start",
                marginLeft: "1rem",
                alignItems: "center",
              }}
            >
              <Select
                sx={{ flex: 1, maxWidth: "165px" }}
                value={selectedBlock}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedBlock(e.target.value);
                }}
              >
                {modules.map((module: ModuleRecord) => (
                  <MenuItem key={module.id} value={module.id}>
                    {module.translations[module.defaultLanguage].title}
                  </MenuItem>
                ))}
                {hasNoSessionSession ? (
                  <MenuItem value={-1}>
                    {translations["scanner.noBlock"]}
                  </MenuItem>
                ) : null}
              </Select>
              {selectedBlock === "" ? null : (
                <IconButton
                  color="primary"
                  sx={{ height: "100%", padding: 0, marginLeft: "1rem" }}
                  onClick={() => {
                    setSelectedBlock("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ) : null
      ) : modules.length > 0 ? (
        <Box
          mb={"1rem"}
          sx={{
            minHeight: "56px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            color="primary"
            onClick={() => {
              setShowModuleFilter(true);
            }}
          >
            <FilterAltIcon />
          </IconButton>
        </Box>
      ) : null}

      {/*Sessions*/}
      {/*}
      {filteredActivities.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography sx={{ marginRight: "1rem" }}>Sitzung</Typography>
          <Select
            sx={{ flex: 1, boxSizing: "border-box" }}
            value={currentSelectSession?.id.toString() || ""}
            onChange={(event: SelectChangeEvent) => {
              setCurrentSelectedScanBlock(null);
              setCurrentSelectSession(
                sessions.find(
                  (session) => session.id.toString() == event.target.value
                )
              );
            }}
          >
            {filteredActivities.map((session, index) => {
              return (
                <MenuItem key={index} value={session.id}>
                  {session.translations[language].title}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      )}
      {/*}
      {/* ZEITGRUPPE */}
      {/*}
      {filteredModules.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginRight: "1rem" }}>Zeitgruppe</Typography>
          <Select
            sx={{ flex: 1 }}
            value={currentSelectedScanBlock?.id.toString() || ""}
            onChange={(e: SelectChangeEvent) => {
              setCurrentSelectSession(undefined);
              setCurrentSelectedScanBlock(
                modules.find(
                  (module) => module.id.toString() == e.target.value
                ) ?? null
              );
            }}
          >
            {filteredModules.map((module: ModuleRecord) => (
              <MenuItem key={module.id} value={module.id}>
                {module.translations[module.defaultLanguage].title}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}{*/}

      {/* mixed */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ marginRight: "1rem" }}>Sitzung</Typography>
        <Select
          sx={{ flex: 1 }}
          value={
            currentSelectSession
              ? "session-" + currentSelectSession.id.toString()
              : currentSelectedScanBlock
              ? "module-" + currentSelectedScanBlock.id.toString()
              : ""
          }
          onChange={(e: SelectChangeEvent) => {
            const selectedValue = e.target.value;
            console.log(selectedValue);

            if (selectedValue !== "" && selectedValue) {
              const [type, id] = selectedValue.split("-");

              if (type) {
                if (type === "module") {
                  setCurrentSelectSession(null);
                  setCurrentSelectedScanBlock(
                    modules.find((module) => module.id.toString() == id) ?? null
                  );
                } else {
                  setCurrentSelectSession(
                    sessions.find((session) => session.id.toString() == id) ??
                      null
                  );
                  setCurrentSelectedScanBlock(null);
                }
              }
            }
          }}
        >
          {[
            ...filteredActivities.map((session) => ({
              type: "session",
              id: session.id,
              title: session.translations[language].title || "",
              sortorder: session.sortorder,
              blockId: session.blockId,
            })),
            ...filteredModules
              .filter((module) => {
                console.log("selectedBlock", selectedBlock);
                if (selectedBlock) {
                  return Number(module.id) === Number(selectedBlock);
                } else {
                  return true;
                }
              })
              .map((module) => ({
                type: "module",
                id: module.id,
                title: module.translations[module.defaultLanguage].title || "",
                sortorder: module.sortorder,
                blockId: null,
              })),
          ]
            .sort((a, b) => a.sortorder - b.sortorder)
            .map((item, index) => {
              if (
                item.blockId &&
                filteredModules.find((module) => item.blockId === module.id)
              ) {
                return (
                  <MenuItem
                    sx={{ marginLeft: "1rem", paddingLeft: "1rem" }}
                    key={item.type + "-" + item.id}
                    value={item.type + "-" + item.id}
                  >
                    {item.title}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={item.type + "-" + item.id}
                    value={item.type + "-" + item.id}
                  >
                    {item.title}
                  </MenuItem>
                );
              }
            })}
          {/*}{filteredActivities.map((session, index) => {
            return (
              <MenuItem
                key={"session-" + session.id}
                value={"session-" + session.id}
              >
                {session.translations[language].title}
              </MenuItem>
            );
          })}
          {filteredModules.map((module: ModuleRecord) => (
            <MenuItem key={"module-" + module.id} value={"module-" + module.id}>
              {module.translations[module.defaultLanguage].title}
            </MenuItem>
          ))}{*/}
        </Select>
      </Box>

      {currentSelectSession && (
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <SessionInfo
            session={currentSelectSession}
            currentLocation={currentLocation}
            block={currentModule!}
            defaultLanguage={language}
          />
        </Box>
      )}
      {currentSelectedScanBlock && (
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <ModuleInfo
            block={currentSelectedScanBlock}
            defaultLanguage={language}
          />
        </Box>
      )}
      <Box flex="1"></Box>
      {currentSelectSession && (
        <Box>
          <ContainedStyledButton
            sx={[{ width: "100%" }]}
            onClick={() => {
              setSelectedSession(currentSelectSession);
              setCurrentSelectSession(undefined);
            }}
          >
            {translations["select"]}
          </ContainedStyledButton>
        </Box>
      )}
      {currentSelectedScanBlock && (
        <Box>
          <ContainedStyledButton
            sx={[{ width: "100%" }]}
            onClick={() => {
              setSelectedScanBlock(currentSelectedScanBlock);
              setCurrentSelectedScanBlock(null);
            }}
          >
            {translations["select"]}
          </ContainedStyledButton>
        </Box>
      )}
    </Box>
  );
};

export default SessionSelect;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
} from "@mui/material";

import {
  ScanEventRouterResponse,
  LocationRecord,
  SessionRecord,
  FieldRecord,
  IRecord,
  LSEventRecord,
  FilterObject,
  LSRegEventRecord,
  LSRegFieldEventRecord,
  LSRegistrationFieldRecord,
  LSRegistrationRecord,
  LSSessionRecord,
  Person,
  QrCode,
  ScanRecord,
  ScanResponse,
  SnackBarObject,
  CheckType,
  LSScanRecord,
  ModuleRecord,
} from "../../../../../interfaces/Interfaces";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import Html5QrcodeScanner from "./Html5QRScanner";
import { getTranslations } from "../../../../../translations/Translations";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

import { formatDateTime } from "../../../../../helpers/DateFunctions";
import isJson from "../../../../../helpers/isJson";
import ChoiceDialog from "../../../../../components/ChoiceDialog";

import { syncRequest } from "../helpers";

import "../style.scss";
import { Loading } from "./Loading";
import SyncModal from "./SyncModal";
import SessionSelect from "./SessionSelect";
import Registrations from "./Registrations";
import NoRegistrations from "./NoRegistrations";
import ScanList from "../ScanList";
import ScanListControls from "./ScanListControls";
import PersonModal from "./modals/PersonModal";

const Scanner = ({ setSelectedEvent }: { setSelectedEvent: Function }) => {
  const { event, sessions, locations, modules } = useRouteLoaderData(
    "scanevent"
  ) as ScanEventRouterResponse;
  const [registrationList, setRegistrationList] = useState<Person[]>([]);
  const [qrResult, setQrResult] = useState<string>("");
  const [startScanner, setStartScanner] = useState<boolean>(false);
  const [activeScan, setActiveScan] = useState<ScanRecord | null>(null);
  const [selectedSession, setSelectedSession] = useState<SessionRecord | null>(
    null
  );

  const [selectedScanBlock, setSelectedScanBlock] =
    useState<ModuleRecord | null>(null);

  const [currentSelectSession, setCurrentSelectSession] =
    useState<SessionRecord>();
  const [currentLocation, setCurrentLocation] = useState<LocationRecord>();
  const [noSessionEvent, setNoSessionEvent] = useState<boolean>(false);
  const [serverRegistrations, setServerRegistrations] = useState<IRecord[]>();
  const [registrationsFields, setRegistrationFields] =
    useState<FieldRecord[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<FilterObject[]>([]);
  const [filteredScanList, setFilteredScanList] = useState<ScanRecord[]>([]);
  const [currentCheckedInFilter, setCurrentCheckedInFilter] =
    useState<string>("");
  const [scanList, setScanList] = useState<ScanRecord[]>([]);
  const [checkType, setCheckType] = useState<CheckType>("checkin");
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [showSyncQuestionModal, setShowSyncQuestionModal] =
    useState<boolean>(false);
  const [showSyncEventQuestionModal, setShowSyncEventQuestionModal] =
    useState<boolean>(false);
  const [showSyncCompleted, setShowSyncCompleted] = useState<boolean>(false);
  const [showScanChoice, setShowScanChoice] = useState<boolean>(false);
  const [continuousScan, setContinuousScan] = useState<boolean>(false);
  const [snackBarObject, setSnackbarObject] = useState<SnackBarObject>({
    type: "",
    message: "",
  });
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const translations = getTranslations();

  const searchTextField = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [iosInitialized, setIosInitialized] = useState<boolean>(false);

  const [isFFAEvent, setIsFFAEvent] = useState<boolean | null>(null);

  const navigate = useNavigate();

  const handleStartScanner = () => {
    setShowScanChoice(true);
  };

  const clearSnackBar = () => {
    setTimeout(() => {
      setSnackbarObject({
        message: "",
        type: "",
      });
    }, 2000);
  };

  const isValidQrCode = (obj: QrCode): boolean => {
    const requiredProperties: (keyof QrCode)[] = [
      "eventId",
      "registrationId",
      "firstname",
      "lastname",
      "maklerNr",
    ];
    const hasAllProperties = requiredProperties.every((prop) =>
      obj.hasOwnProperty(prop)
    );
    return hasAllProperties;
  };

  const handleCodeScannerResult = (code: string) => {
    const qrResult = `{${code}}`;
    console.log(qrResult);
    if (isJson(qrResult)) {
      if (isValidQrCode(JSON.parse(qrResult))) {
        setQrResult(qrResult);
      }
    } else {
      if (continuousScan) {
        setSnackbarObject({
          message: translations["scanner.invalidQr"],
          type: "error",
        });
        clearSnackBar();
      } else {
        //open error
        setErrorMessage(translations["scanner.invalidQr"]);
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    if (sessions.length === 0) {
      setTitle("Scan " + event.translations[event.defaultLanguage].title);
      setNoSessionEvent(true);
    } else {
      setTitle(translations["scanner.selectSession"]);
    }
  }, []);

  useEffect(() => {
    let newList: ScanRecord[] = [...scanList];
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        newList = newList.filter(filter.filterFunction);
      });
    }
    setFilteredScanList(newList);
  }, [scanList, filters]);

  useEffect(() => {
    if (searchValue === "") {
      removeFilterByIdentifier("searchFilter");
    }
  }, [searchValue]);

  const removeFilterByIdentifier = (
    identifierToRemove: string,
    callback?: () => void
  ): void => {
    setFilters(
      filters.filter((filterObj) => filterObj.identifier !== identifierToRemove)
    );
  };

  useEffect(() => {
    if (currentCheckedInFilter !== "") {
      switch (currentCheckedInFilter) {
        case "checkedIn":
          addFilter({
            identifier: "checkedInFilter",
            filterFunction: (scan: ScanRecord) => scan.type === "checkIn",
          });
          break;
        case "checkedOut":
          addFilter({
            identifier: "checkedInFilter",
            filterFunction: (scan: ScanRecord) => scan.type === "checkOut",
          });
          break;
        default:
          break;
      }
    } else {
      removeFilterByIdentifier("checkedInFilter");
    }
  }, [currentCheckedInFilter]);

  const addFilter = async (newFilter: FilterObject) => {
    const filterIsPresent = filters.some(
      (filterObj) => filterObj.identifier === newFilter.identifier
    );
    if (!filterIsPresent) {
      setFilters((prevFilters) => [...prevFilters, newFilter]);
    } else {
      setFilters((prevFilters) => {
        return prevFilters.map((filter) => {
          if (filter.identifier === newFilter.identifier) {
            return newFilter;
          } else {
            return filter;
          }
        });
      });
    }
  };

  const fetchRegistrationsAndFields = (type?: string, id?: number) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    interface FilterRecord {
      property: string;
      value: number;
    }

    const fetchData = async (
      filters: FilterRecord[],
      url: string,
      succesFn: Function
    ) => {
      try {
        filters.push({ property: "eventId", value: event.id });

        let searchParams = new URLSearchParams({
          filter: JSON.stringify(filters),
        });

        let urlLink = url + searchParams.toString();

        const response = await fetch(urlLink, {
          signal: signal,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          },
        });

        if (!response.ok) {
          // Handle response errors if necessary
          console.error("Error fetching data:", response);
          return;
        }

        const data = await response.json();
        succesFn(data.data);
      } catch (error) {
        // Handle any other errors that might occur during fetch
        console.error("Error:", error);
      }
    };

    let filterObject: FilterRecord[] = [];

    type LSRegFieldEventRecordOrOther =
      | LSRegFieldEventRecord
      | LSRegEventRecord
      | LSEventRecord;

    const setLocalData = (key: string, setterKey: string, setter: Function) => {
      const localStorageData = localStorage.getItem(key);
      const parsedLocalStorageData: any = localStorageData
        ? JSON.parse(localStorageData)
        : [];
      const localEventindex = parsedLocalStorageData.findIndex(
        (LSEvent: LSRegFieldEventRecordOrOther) => LSEvent.eventId === event.id
      );
      if (localEventindex !== -1) {
        const currentEvent: LSRegFieldEventRecordOrOther =
          parsedLocalStorageData[localEventindex];
        //check if session...

        if (selectedSession || selectedScanBlock) {
          const idToMatch = selectedSession
            ? selectedSession.id
            : selectedScanBlock?.id;
          const keyToMatch = selectedSession ? "session" : "block";

          const dataIndex = currentEvent.data.findIndex(
            (data: any) => data[keyToMatch] === idToMatch
          );

          if (dataIndex !== -1) {
            const matchedData = currentEvent.data[dataIndex];

            if (setterKey in matchedData) {
              setter(matchedData[setterKey as keyof typeof matchedData]);
              return true;
            }
          }
        } else {
          setter(
            currentEvent.data[0][
              setterKey as keyof (typeof currentEvent.data)[0]
            ]
          );
          return true;
        }

        return false;
      }
    };

    const fetchRegistrationfieldsData = () => {
      if (navigator.onLine) {
        fetchData(
          filterObject,
          "/api/scanner/registrationfields?",
          (data: FieldRecord[]) => {
            const newData = data.map((record) => {
              switch (record.translations[event.defaultLanguage].title) {
                case "Vorname":
                  record.defaultfield = "firstname";
                  break;
                case "Nachname":
                  record.defaultfield = "lastname";
                  break;
                case "E-Mail":
                  record.defaultfield = "email";
                  break;
                default:
                  break;
              }

              return record;
            });
            setRegistrationFields(newData);
          }
        );
      } else {
        const registrationFieldData = localStorage.getItem(
          "registrationFieldData"
        );
        if (registrationFieldData) {
          setLocalData(
            "registrationFieldData",
            "fields",
            setRegistrationFields
          );
        } else {
          //Show a warning that we have no registrations ...
        }
      }
    };

    const fetchRegistrations = () => {
      if (type === "session" && id) {
        filterObject.push({ property: "activityId", value: id });
      }

      if (type === "block" && id) {
        filterObject.push({ property: "blockId", value: id });
      }

      if (navigator.onLine) {
        fetchData(
          filterObject,
          "/api/scanner/registrations?",
          (data: IRecord[]) => {
            console.log("data", data);
            setServerRegistrations(data);
          }
        );
      } else {
        const registrationData = localStorage.getItem("registrationData");
        if (registrationData) {
          console.log("registrationData", registrationData);
          setLocalData(
            "registrationData",
            "registrations",
            setServerRegistrations
          );
        } else {
          //Show a warning that we have no registrations ...
        }
      }
    };

    //Setting data...
    setLocalData("registrationData", "registrations", setServerRegistrations);
    setLocalData("registrationFieldData", "fields", setRegistrationFields);

    if (!setLocalData("tempScanData", "scans", setScanList)) {
      setLocalData("scanData", "scans", setScanList);
    }

    fetchRegistrationfieldsData();
    fetchRegistrations();

    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    if (selectedSession) {
      setTitle(
        "Scan " + selectedSession.translations[event.defaultLanguage].title
      );
      setIsFFAEvent(selectedSession.scanFFA);
      fetchRegistrationsAndFields("session", selectedSession.id);
    } else {
      setIsFFAEvent(null);
    }
  }, [selectedSession, event]);

  useEffect(() => {
    if (selectedScanBlock) {
      setTitle(
        "Scan " + selectedScanBlock.translations[event.defaultLanguage].title
      );
      setIsFFAEvent(true);
      console.log("fetching");
      fetchRegistrationsAndFields("block", selectedScanBlock.id);
    } else {
      setIsFFAEvent(null);
    }
  }, [selectedScanBlock, event]);

  useEffect(() => {
    if (noSessionEvent === true) {
      fetchRegistrationsAndFields();
    }
  }, [noSessionEvent, event]);

  interface FieldType {
    id: number;
    type: string | null;
  }

  useEffect(() => {
    if (serverRegistrations && registrationsFields) {
      if (serverRegistrations.length !== 0) {
        const defaultFields: FieldType[] = [];
        //Map to registrations ...
        for (let i = 0; i < registrationsFields.length; i++) {
          if (registrationsFields[i].defaultfield) {
            defaultFields.push({
              id: registrationsFields[i].id,
              type: registrationsFields[i].defaultfield,
            });
          }
        }

        const getCorrectValue = (
          defaultFieldValue: string,
          record: IRecord
        ): string => {
          const value = defaultFields.find(
            (field) => field.type === defaultFieldValue
          );
          if (value) {
            return record.values["field" + value.id.toString()];
          } else {
            return "";
          }
        };

        const mappedRegistrations: Person[] = serverRegistrations.map(
          (regRecord) => {
            const newRecord = {
              id: regRecord.id,
              firstname: getCorrectValue("firstname", regRecord),
              lastname: getCorrectValue("lastname", regRecord),
              email: getCorrectValue("email", regRecord),
              publicId: regRecord.publicId,
              checkedIn: "",
              checkedOut: "",
            };

            return newRecord;
          }
        );

        setRegistrationList(mappedRegistrations);
      }

      setIsLoading(false);
    }
  }, [serverRegistrations, registrationsFields]);

  useEffect(() => {
    //Check if person is in registrations...
    if (qrResult) {
      const person = JSON.parse(qrResult);
      const activityId = selectedSession ? selectedSession.id : null;
      const blockId = selectedScanBlock ? selectedScanBlock.id : null;

      if (isFFAEvent) {
        setScanList((scans) => {
          return [
            ...scans,
            {
              firstname: person.firstname,
              lastname: person.lastname,
              publicId: person.registrationId,
              activityId: activityId,
              blockId: blockId,
              date: Date.now().toString(),
              type: checkType,
              deleted: false,
            },
          ];
        });

        if (continuousScan) {
          setSnackbarObject({
            message: `${person.firstname} ${person.lastname} ${
              checkType === "checkin"
                ? translations["scanner.checkedIn"]
                : translations["scanner.checkedOut"]
            }`,
            type: checkType,
          });
          clearSnackBar();
        }
      } else {
        const searchedPerson = registrationList.find((item) => {
          return item.publicId === person.registrationId;
        });
        if (searchedPerson) {
          setScanList((scans) => {
            return [
              ...scans,
              {
                firstname: searchedPerson.firstname,
                lastname: searchedPerson.lastname,
                registrationId: searchedPerson.id,
                publicId: searchedPerson.publicId,
                activityId: activityId,
                blockId: blockId,
                date: Date.now().toString(),
                type: checkType,
                deleted: false,
              },
            ];
          });

          if (continuousScan) {
            setSnackbarObject({
              message: `${searchedPerson.firstname} ${
                searchedPerson.lastname
              } ${
                checkType === "checkin"
                  ? translations["scanner.checkedIn"]
                  : translations["scanner.checkedOut"]
              }`,
              type: checkType,
            });
            clearSnackBar();
          }
        } else {
          if (continuousScan) {
            setSnackbarObject({
              message: translations["scanner.personNotFound"],
              type: "error",
            });
            clearSnackBar();
          } else {
            setErrorMessage(translations["scanner.personNotFound"]);
            setShowError(true);
          }
        }
      }
      setQrResult("");
    }
  }, [qrResult]);

  useEffect(() => {
    if (
      currentSelectSession &&
      currentSelectSession.locationId &&
      locations.length > 0
    ) {
      setCurrentLocation(
        locations.find(
          (location) => location.id == currentSelectSession.locationId
        )
      );
    }
  }, [currentSelectSession]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue !== "") {
        //removeFilterByIdentifier('searchFilter');
        const searchValueLower = searchValue.toLowerCase().trim();
        addFilter({
          identifier: "searchFilter",
          filterFunction: (person) => {
            return (
              person.firstname.toLowerCase().includes(searchValueLower) ||
              person.lastname.toLowerCase().includes(searchValueLower)
            );
          },
        });
      } else {
        removeFilterByIdentifier("searchFilter");
      }
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    tempSaveToLocalStorage();
  }, [scanList]);

  //There is redundancy in this code ... simplify when optmizing.
  const saveServerRegistrations = (
    callback: Function | null,
    type: string,
    id?: number
  ) => {
    const registrationData = localStorage.getItem("registrationData");
    const saveRegistrationData: LSRegEventRecord[] = registrationData
      ? JSON.parse(registrationData)
      : [];
    const eventIndex = saveRegistrationData.findIndex(
      (LSEvent) => LSEvent.eventId === event.id
    );

    const returnData = () => {
      const event = saveRegistrationData[eventIndex];
      const eventData = event?.data ?? [];
      return id
        ? saveRegistrationDataFn(type, eventData, id)
        : saveRegistrationDataFn(type, eventData);
    };

    if (eventIndex !== -1) {
      saveRegistrationData[eventIndex].data = returnData();
    } else {
      saveRegistrationData.push({
        eventId: event.id,
        data: returnData(),
      });
    }

    localStorage.setItem(
      "registrationData",
      JSON.stringify(saveRegistrationData)
    );

    if (callback) {
      callback();
    }
  };

  const saveServerRegistrationFields = (
    callback: Function | null,
    type: string,
    id?: number
  ) => {
    const registrationFieldData = localStorage.getItem("registrationFieldData");
    const saveRegistrationFieldData: LSRegFieldEventRecord[] =
      registrationFieldData ? JSON.parse(registrationFieldData) : [];
    const eventIndex = saveRegistrationFieldData.findIndex(
      (LSEvent) => LSEvent.eventId === event.id
    );

    const returnData = () => {
      const event = saveRegistrationFieldData[eventIndex];
      const eventData = event?.data ?? [];
      return id
        ? saveRegistrationFieldDataFn(type, eventData, id)
        : saveRegistrationFieldDataFn(type, eventData);
    };

    if (eventIndex !== -1) {
      saveRegistrationFieldData[eventIndex].data = returnData();
    } else {
      saveRegistrationFieldData.push({
        eventId: event.id,
        data: returnData(),
      });
    }

    localStorage.setItem(
      "registrationFieldData",
      JSON.stringify(saveRegistrationFieldData)
    );

    if (callback) {
      callback();
    }
  };

  const saveScans = (
    key: string,
    type: string,
    id?: number | null,
    callback?: Function
  ) => {
    const registrationData = localStorage.getItem(key);
    const saveScansData: LSEventRecord[] = registrationData
      ? JSON.parse(registrationData)
      : [];
    const eventIndex = saveScansData.findIndex(
      (LSEvent) => LSEvent.eventId === event.id
    );

    const returnData = () => {
      const event = saveScansData[eventIndex];
      const eventData = event?.data ?? [];
      return id
        ? saveScanDataFn(type, eventData, id)
        : saveScanDataFn(type, eventData);
    };

    if (eventIndex !== -1) {
      saveScansData[eventIndex].data = returnData();
    } else {
      saveScansData.push({
        eventId: event.id,
        data: returnData(),
      });
    }

    localStorage.setItem(key, JSON.stringify(saveScansData));

    if (callback) {
      callback();
    }
  };

  const removeLocalScans = (key: string) => {
    const registrationData = localStorage.getItem(key);

    if (registrationData) {
      const saveScansData: LSEventRecord[] = JSON.parse(registrationData);
      const newScanData: LSEventRecord[] = [...saveScansData];
      const eventIndex = saveScansData.findIndex(
        (LSEvent) => LSEvent.eventId === event.id
      );

      if (eventIndex !== -1) {
        let updatedEventData = { ...saveScansData[eventIndex] }; // Make a copy of the event data

        if (selectedSession || selectedScanBlock) {
          const idToMatch = selectedSession
            ? selectedSession.id
            : selectedScanBlock?.id;
          const keyToMatch = selectedSession ? "session" : "block";

          const dataIndex = updatedEventData.data.findIndex(
            (data: any) => data[keyToMatch] === idToMatch
          );

          if (dataIndex !== -1) {
            updatedEventData.data.splice(dataIndex, 1);
          }

          /*const sessionIndex = updatedEventData.data.findIndex(
            (session) => session.session === selectedSession.id
          );

          if (sessionIndex !== -1) {
            updatedEventData.data.splice(sessionIndex, 1); // Remove the selected session
          }*/
        } else {
          if (noSessionEvent) {
            updatedEventData.data = [];
          }
        }

        if (updatedEventData.data.length > 0) {
          newScanData[eventIndex] = updatedEventData; // Update the event data with the modified session data
        } else {
          newScanData.splice(eventIndex, 1); // Remove the entire event if no sessions are left
        }

        localStorage.setItem(key, JSON.stringify(newScanData)); // Save the updated data back to localStorage
      }
    }
  };

  const saveData = (
    idType: string, // Block or session or event
    type: "scans" | "registrations" | "registrationFields",
    currentData?: any,
    id?: number
  ) => {
    let newDataObject = currentData ? [...currentData] : [];
    let dataList;

    switch (type) {
      case "scans":
        dataList = scanList;
        break;
      case "registrations":
        dataList = serverRegistrations;
        break;
      case "registrationFields":
        dataList = registrationsFields;
        break;
      default:
        throw new Error("Invalid type");
    }

    const datalistKey = type === "registrationFields" ? "fields" : type;
    let mappedData =
      idType && id !== undefined
        ? { [idType]: id, [datalistKey]: dataList }
        : { [datalistKey]: dataList };

    if (idType && id) {
      const index = newDataObject.findIndex((data: any) => data[idType] === id);
      if (index !== -1) {
        newDataObject[index] = mappedData;
      } else {
        newDataObject.push(mappedData);
      }
    } else {
      newDataObject =
        newDataObject.length > 0 &&
        typeof newDataObject[0][idType] !== "undefined"
          ? newDataObject
          : [mappedData];
    }

    return newDataObject;
  };

  const saveScanDataFn = (
    type: string,
    currentData?: LSScanRecord[] | LSSessionRecord[],
    id?: number
  ) => {
    return saveData(type, "scans", currentData, id);
  };

  const saveRegistrationDataFn = (
    type: string,
    currentData?: LSRegistrationRecord[],
    id?: number
  ) => {
    return saveData(type, "registrations", currentData, id);
  };

  const saveRegistrationFieldDataFn = (
    type: string,
    currentData?: LSRegistrationFieldRecord[],
    id?: number
  ) => {
    return saveData(type, "registrationFields", currentData, id);
  };

  const sync = (callback?: Function) => {
    setIsSyncing(true);
    const data = scanList
      .filter((scan) => scan.deleted === false)
      .map((scan) => {
        return {
          publicId: scan.publicId,
          eventId: event.id,
          activityId: scan.activityId,
          blockId: scan.blockId,
          checkIn: scan.type === "checkin" ? true : false,
          checkDate: formatDateTime(parseInt(scan.date, 10)),
        };
      });

    if (callback) {
      syncRequest(data, (result: any) => {
        setIsSyncing(false);
        callback(result);
      });
    } else {
      syncRequest(data);
    }
  };

  const onSyncClick = (callback?: Function) => {
    sync((result: ScanResponse) => {
      if (result.success) {
        //Sync worked -- remove all local scans
        setScanList([]);
        removeLocalScans("scanData");
        removeLocalScans("tempScanData");
        setShowSyncCompleted(true);
        //clear local storage if there is?
      } else {
        /* show error msg */
      }

      if (callback) {
        callback();
      }
    });
  };

  const saveToLocalStorage = (callback: Function) => {
    if (selectedSession || selectedScanBlock) {
      if (selectedSession) {
        saveServerRegistrationFields(
          () => {
            saveServerRegistrations(
              () => {
                saveScans("scanData", "session", selectedSession.id, callback);
              },
              "session",
              selectedSession.id
            );
          },
          "session",
          selectedSession.id
        );
      }

      if (selectedScanBlock) {
        saveServerRegistrationFields(
          () => {
            saveServerRegistrations(
              () => {
                saveScans("scanData", "block", selectedScanBlock.id, callback);
              },
              "block",
              selectedScanBlock.id
            );
          },
          "block",
          selectedScanBlock.id
        );
      }
    } else {
      saveServerRegistrationFields(() => {
        saveServerRegistrations(() => {
          saveScans("scanData", "event", null, callback);
        }, "event");
      }, "event");
    }
  };

  const tempSaveToLocalStorage = (callback?: Function) => {
    if (noSessionEvent) {
      saveScans("tempScanData", "event");
    } else {
      if (selectedSession) {
        saveScans("tempScanData", "session", selectedSession.id);
      }

      if (selectedScanBlock) {
        saveScans("tempScanData", "block", selectedScanBlock.id);
      }
    }
  };

  const clearScanner = () => {
    setScanList([]);
    setSelectedSession(null);
    setSelectedScanBlock(null);
    setRegistrationList([]);
    setTitle(translations["scanner.selectSession"]);
  };

  const backTapSave = () => {
    if (selectedSession || selectedScanBlock) {
      saveToLocalStorage(() => {
        removeLocalScans("tempScanData");
        clearScanner();
      });
    } else {
      saveToLocalStorage(() => {
        removeLocalScans("tempScanData");
        //window.open("/scanner", "_self");
        setSelectedEvent(null);
        setSelectedScanBlock(null);
        navigate("/scanner", { replace: false });
      });
    }
  };

  const backTap = () => {
    if (selectedSession || selectedScanBlock) {
      clearScanner();
    } else {
      //window.open("/scanner", "_self");
      setSelectedEvent(null);
      navigate("/scanner", { replace: false });
    }
  };

  const removeScan = (scan: ScanRecord) => {
    setScanList((prevList) => {
      return prevList.map((item) => {
        if (
          item.registrationId === scan.registrationId &&
          scan.date === item.date
        ) {
          item.deleted = true;
        }
        return item;
      });
    });
  };

  /* START JSX */
  return (
    <Box display="flex" flexDirection="column" alignItems={"stretch"} flex={1}>
      <audio src="/250-milliseconds-of-silence.mp3" ref={audioRef}></audio>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => {
              if (scanList.length > 0) {
                if (navigator.onLine) {
                  if (
                    !noSessionEvent &&
                    !selectedSession &&
                    !selectedScanBlock
                  ) {
                    backTapSave();
                  } else {
                    onSyncClick(() => {
                      setShowSyncQuestionModal(true);
                    });
                  }
                } else {
                  backTapSave();
                }
              } else {
                backTap();
              }
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textWrap: "nowrap",
              whiteSpace: "pre",
              overflow: "hidden",
            }}
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Html5QrcodeScanner
        start={startScanner}
        stop={() => {
          setStartScanner(false);
        }}
        continuous={continuousScan}
        snackBarObject={snackBarObject}
        onResult={handleCodeScannerResult}
        audioRef={audioRef.current}
      />
      {/* QR error window*/}
      <ChoiceDialog
        open={[showError, setShowError]}
        title={translations["scanner.alert"]}
        description={errorMessage}
        buttons={[
          <Button
            variant="contained"
            onClick={() => {
              setShowError(false);
            }}
          >
            {translations["continue"]}
          </Button>,
        ]}
      />
      {/* Single scan / continuous */}
      <ChoiceDialog
        open={[showScanChoice, setShowScanChoice]}
        title={translations["scanner.alertScan"]}
        description={translations["scanner.alertScanDescription"]}
        buttons={[
          <Button
            variant="contained"
            onClick={() => {
              setContinuousScan(false);
              setStartScanner(true);
              setShowScanChoice(false);
            }}
          >
            {translations["scanner.singleScan"]}
          </Button>,
          <Button
            variant="contained"
            onClick={() => {
              setContinuousScan(true);
              setStartScanner(true);
              setShowScanChoice(false);
            }}
          >
            {translations["scanner.continuousScan"]}
          </Button>,
        ]}
      />
      {/* Sync Modal*/}
      <ChoiceDialog
        open={[showSyncEventQuestionModal, setShowSyncEventQuestionModal]}
        title={translations["scanner.alert"]}
        description={translations["scanner.alertSyncOneDescription"]}
        buttons={[
          <Button
            variant="contained"
            onClick={() => {
              onSyncClick();
              setShowSyncEventQuestionModal(false);
            }}
          >
            {translations["scanner.sync"]}
          </Button>,
          <Button
            onClick={() => {
              setShowSyncEventQuestionModal(false);
            }}
          >
            {translations["cancel"]}
          </Button>,
        ]}
      />
      {/* Backtap Sync Modal*/}
      <ChoiceDialog
        open={[showSyncQuestionModal, setShowSyncQuestionModal]}
        title={translations["scanner.syncCompletedTitle"]}
        description={translations["scanner.syncCompleted"]}
        buttons={[
          <Button
            onClick={() => {
              setShowSyncQuestionModal(false);
              clearScanner();
            }}
          >
            {translations["confirm"]}
          </Button>,
        ]}
      />

      {/* Sync completed Modal*/}
      <ChoiceDialog
        open={[showSyncCompleted, setShowSyncCompleted]}
        title={translations["scanner.syncCompletedTitle"]}
        description={translations["scanner.syncCompleted"]}
        buttons={[
          <Button
            onClick={() => {
              setShowSyncCompleted(false);
            }}
          >
            {translations["confirm"]}
          </Button>,
        ]}
      />
      {/* PERSON MODAL */}
      <PersonModal
        activeScan={activeScan}
        setActiveScan={setActiveScan}
        event={event}
        selectedSession={selectedSession}
        onScanRemove={removeScan}
      />
      {/* syncing */}
      <SyncModal open={isSyncing} />
      {/* Actual Views */}

      {noSessionEvent || selectedSession || selectedScanBlock ? (
        <>
          {isFFAEvent ? (
            <Box
              className="scanner-container"
              sx={{ display: "flex", flexDirection: "column", flex: 1 }}
            >
              <ScanListControls
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                inputRef={searchTextField}
                clearFn={clearScanner}
                syncModalShowFn={setShowSyncEventQuestionModal}
              />
              <ScanList
                scanSetter={setActiveScan}
                scanList={filteredScanList}
              />
              <Registrations
                checkType={checkType}
                setCheckType={setCheckType}
                audioRef={audioRef.current}
                handleStartScanner={handleStartScanner}
                iosInitialized={[iosInitialized, setIosInitialized]}
              />
            </Box>
          ) : (serverRegistrations && serverRegistrations.length > 0) ||
            registrationList.length > 0 ? (
            <>
              {/* controls */}
              <ScanListControls
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                inputRef={searchTextField}
                clearFn={clearScanner}
                syncModalShowFn={setShowSyncEventQuestionModal}
              />
              <ScanList
                scanSetter={setActiveScan}
                scanList={filteredScanList}
              />
              <Registrations
                checkType={checkType}
                setCheckType={setCheckType}
                audioRef={audioRef.current}
                handleStartScanner={handleStartScanner}
                iosInitialized={[iosInitialized, setIosInitialized]}
              />
            </>
          ) : isLoading ? (
            <Loading />
          ) : (
            <NoRegistrations />
          )}
        </>
      ) : (
        <SessionSelect
          currentSelectSession={currentSelectSession}
          sessions={sessions}
          modules={modules}
          language={event.defaultLanguage}
          currentLocation={currentLocation}
          setSelectedSession={setSelectedSession}
          setCurrentSelectSession={setCurrentSelectSession}
          setSelectedScanBlock={setSelectedScanBlock}
        />
      )}
    </Box>
  );
};

export default Scanner;

import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FieldRecord } from "../interfaces/Interfaces";
import { getTranslations } from "../translations/Translations";
import { DatePicker } from "@mui/x-date-pickers";
import { useField } from "formik";

interface RegistrationfieldProps {
  formik: any;
  field: FieldRecord;
}

export default function Registrationfield(props: RegistrationfieldProps) {
  const translations = getTranslations();
  let field = props.field;
  let formik = props.formik;
  const meta = formik.getFieldMeta("field" + field.id);

  if (field.type === "title") {
    return (
      <Box className="title" key={field.id} mb={1}>
        {field.translations[field.defaultLanguage].title}
      </Box>
    );
  } else if (field.type === "text") {
    return (
      <Box className="text" key={field.id} mb={1}>
        {field.translations[field.defaultLanguage].title}
      </Box>
    );
  } else if (field.type === "divider") {
    return <Box className="divider"></Box>;
  } else if (
    field.type === "textfield" ||
    field.type === "telephonefield" ||
    field.type === "emailfield"
  ) {
    return (
      <Box display="flex" flexDirection="row" mb={1} key={field.id}>
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl sx={{ minWidth: "300px" }}>
          <TextField
            size="small"
            {...formik.getFieldProps("field" + field.id)}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ""}
          ></TextField>
        </FormControl>
      </Box>
    );
  } else if (field.type === "textarea") {
    return (
      <Box key={field.id}>
        <Box className="fieldlabel">
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl fullWidth>
          <TextField
            size="small"
            multiline={true}
            minRows={3}
            {...formik.getFieldProps("field" + field.id)}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ""}
          ></TextField>
        </FormControl>
      </Box>
    );
  } else if (field.type === "radiobuttons") {
    let options = field.translations[field.defaultLanguage].options;
    return (
      <Box display="flex" flexDirection="row" key={field.id} mb={1}>
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <Box>
          <FormControl fullWidth size="small">
            <Select
              name={"field" + field.id}
              value={formik.values["field" + field.id]}
              error={Boolean(
                formik.touched["field" + field.id] &&
                  formik.errors["field" + field.id]
              )}
              onChange={(e) => {
                formik.setFieldValue("field" + field.id, e.target.value);
              }}
              MenuProps={
                options && options.length > 10
                  ? {
                      MenuListProps: {
                        sx: {
                          display: "flex",
                          flexDirection: "row",
                          maxWidth: "200px",
                          flexWrap: "wrap",
                        },
                      },
                    }
                  : {}
              }
            >
              {options && options.length === 0 ? (
                <MenuItem value="" sx={{ flex: 1 }}>
                  {translations["registrationform.nooptionsdefined"]}
                </MenuItem>
              ) : null}
              {options &&
                options.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option} sx={{ flex: 1 }}>
                      {option.replace("<br />", "\n")}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  } else if (field.type === "multivalues") {
    let options = field.translations[field.defaultLanguage].options;
    return (
      <Box display="flex" flexDirection="row" key={field.id}>
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <Box mb={1}>
          {options &&
            options.map((option: string, index: number) => {
              return (
                <FormControlLabel
                  name={"field" + field.id}
                  value={option}
                  key={index}
                  control={<Checkbox />}
                  checked={formik.values["field" + field.id]
                    .split("<>")
                    .includes(option)}
                  label={option}
                  onChange={(e) => {
                    var stringvalue = formik.values["field" + field.id];
                    var selected = stringvalue.split("<>");

                    if (selected.includes(option)) {
                      selected.splice(selected.indexOf(option), 1);
                    } else {
                      selected.push(option);
                    }
                    formik.setFieldValue(
                      "field" + field.id,
                      selected.join("<>")
                    );
                  }}
                />
              );
            })}
        </Box>
      </Box>
    );
  } else if (field.type === "checkbox") {
    return (
      <Box key={field.id} display="flex" flexDirection="row">
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControlLabel
          name={"field" + field.id}
          value={"true"}
          control={<Checkbox />}
          checked={/true/i.test(formik.values["field" + field.id])}
          label=""
          onChange={(e) => {
            var stringvalue = formik.values["field" + field.id];

            if (stringvalue === "true") {
              stringvalue = "false";
            } else {
              stringvalue = "true";
            }
            formik.setFieldValue("field" + field.id, stringvalue);
          }}
        />
      </Box>
    );
  } else if (field.type === "terms") {
    return (
      <Box
        key={field.id}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControlLabel
          name={"field" + field.id}
          value={"true"}
          control={<Checkbox />}
          checked={/true/i.test(formik.values["field" + field.id])}
          label=""
          onChange={(e) => {
            var stringvalue = formik.values["field" + field.id];

            if (stringvalue === "true") {
              stringvalue = "false";
            } else {
              stringvalue = "true";
            }
            formik.setFieldValue("field" + field.id, stringvalue);
          }}
        />

        {formik.touched["field" + field.id] &&
        formik.errors["field" + field.id] ? (
          <Typography sx={{ fontSize: "0.75rem" }} color="error">
            {formik.errors["field" + field.id]}
          </Typography>
        ) : null}
      </Box>
    );
  } else if (field.type === "combobox") {
    let options = field.translations[field.defaultLanguage].options;
    return (
      <Box key={field.id} display="flex" flexDirection="row">
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl fullWidth size="small">
          <Select
            name={"field" + field.id}
            value={formik.values["field" + field.id]}
            error={Boolean(
              formik.touched["field" + field.id] &&
                formik.errors["field" + field.id]
            )}
            onChange={(e) => {
              formik.setFieldValue("field" + field.id, e.target.value);
            }}
            MenuProps={
              options && options.length > 10
                ? {
                    MenuListProps: {
                      sx: {
                        display: "flex",
                        flexDirection: "row",
                        maxWidth: "200px",
                        flexWrap: "wrap",
                      },
                    },
                  }
                : {}
            }
          >
            {options && options.length === 0 ? (
              <MenuItem value="" sx={{ flex: 1 }}>
                {translations["registrationform.nooptionsdefined"]}
              </MenuItem>
            ) : null}
            {options &&
              options.map((option: string) => {
                return (
                  <MenuItem key={option} value={option} sx={{ flex: 1 }}>
                    {option.replace("<br />", "\n")}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    );
  } else if (field.type === "countryfield") {
    let options = [""];
    return (
      <Box key={field.id} display="flex" flexDirection="row">
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl fullWidth size="small">
          <Select
            name={"field" + field.id}
            value={formik.values["field" + field.id]}
            error={Boolean(
              formik.touched["field" + field.id] &&
                formik.errors["field" + field.id]
            )}
            onChange={(e) => {
              formik.setFieldValue("field" + field.id, e.target.value);
            }}
            MenuProps={
              options && options.length > 10
                ? {
                    MenuListProps: {
                      sx: {
                        display: "flex",
                        flexDirection: "row",
                        maxWidth: "200px",
                        flexWrap: "wrap",
                      },
                    },
                  }
                : {}
            }
          >
            {options.length === 0 ? (
              <MenuItem value="" sx={{ flex: 1 }}>
                {translations["registrationform.nooptionsdefined"]}
              </MenuItem>
            ) : null}
            {options.map((option: string) => {
              return (
                <MenuItem key={option} value={option} sx={{ flex: 1 }}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  } else if (field.type === "datefield") {
    return (
      <Box key={field.id} display="flex" flexDirection="row">
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl size="small">
          <DatePicker
            format="dd.MM.yyyy"
            onAccept={(eventorvalue) => {
              formik.setFieldValue("field" + field.id, eventorvalue);
            }}
            slotProps={{
              textField: {
                size: "small",
                name: "field" + field.id,
                onChange: (eventorvalue) => {
                  formik.setFieldValue("field" + field.id, eventorvalue);
                },
                error: Boolean(
                  formik.touched["field" + field.id] &&
                    formik.errors["field" + field.id]
                ),
                onBlur: formik.handleBlur,
              },
            }}
            value={formik.values["field" + field.id]}
          />
        </FormControl>
      </Box>
    );
  } else if (field.type === "birthdatefield") {
    return (
      <Box key={field.id} display="flex" flexDirection="row">
        <Box className="fieldlabel" minWidth={"300px"} maxWidth={"300px"}>
          <Typography>
            {field.translations[field.defaultLanguage].title}
          </Typography>
        </Box>
        <FormControl size="small">
          <DatePicker
            format="dd.MM.yyyy"
            onAccept={(eventorvalue) => {
              formik.setFieldValue("field" + field.id, eventorvalue);
            }}
            slotProps={{
              textField: {
                size: "small",
                name: "field" + field.id,
                onChange: (eventorvalue) => {
                  formik.setFieldValue("field" + field.id, eventorvalue);
                },
                error: Boolean(
                  formik.touched["field" + field.id] &&
                    formik.errors["field" + field.id]
                ),
                onBlur: formik.handleBlur,
              },
            }}
            value={formik.values["field" + field.id]}
          />
        </FormControl>
      </Box>
    );
  }
  return <Box>{field.type}</Box>;
}

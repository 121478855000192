import { deDE } from "@mui/x-data-grid";
import { IFETranslations } from "../interfaces/Interfaces";

const translations: IFETranslations = {
  de: {
    add: "Hinzufügen",
    select: "Selektieren",
    cancel: "Abbrechen",
    back: "Zurück",
    close: "Zurück",
    event: "Veranstaltung",
    session: "Sitzung",
    continue: "Weiter",
    confirm: "Bestätigen",
    save: "Speichern",
    withoutSave: "Weiter Ohne Speichern",
    remove: "Löschen",
    yes: "Ja",
    no: "Nein",
    notfound: "Die gewünschte Website existiert nicht oder wurde noch nicht veröffentlicht.",
    hide: "Versteckt",
    show: "Sichtbar",

    "errorpage.title": "Oh nein!",
    "errorpage.info":
      "Entschuldigung, ein unerwarteter Fehler ist aufgetreten.",
    "loadingpage.loading": "Bitte warten...",

    "toptoolbar.logoff": "Abmelden",

    "month.january": "Januar",
    "month.february": "Februar",
    "month.march": "März",
    "month.april": "April",
    "month.may": "Mai",
    "month.june": "Juni",
    "month.july": "Juli",
    "month.august": "August",
    "month.september": "September",
    "month.october": "Oktober",
    "month.november": "November",
    "month.december": "Dezember",

    "day.sunday": "Sonntag",
    "day.monday": "Montag",
    "day.tuesday": "Dienstag",
    "day.wednesday": "Mittwoch",
    "day.thursday": "Donnerstag",
    "day.friday": "Freitag",
    "day.saturday": "Samstag",

    "import.upload": "Hochladen",
    "import.file": "Datei auswahlen",
    "import.skiprows": "Tabellenzeilen überspringen",
    "import.title": "Daten importieren",
    "import.nofields": "Keine Daten verfügbar",
    "import.sheet": "Excel-Tabelle",
    "import.nofile": "keine Excel-Tabelle verfügbar",

    "errors.maxlength400": "Dieses Feld darf nur 400 Zeichen enthalten",
    "errors.maxlength20": "Dieses Feld darf nur 20 Zeichen enthalten",
    "errors.requiredfield": "Dieses Feld ist ein Pflichtfeld",
    "errors.invaliddate": "Dieses Datum ist nicht richtig",
    "errors.invalidnumber": "Diese Zahl ist nicht richtig",
    "errors.invalidemail": "Diese E-Mail ist nicht richtig",
    "errors.youtubeLinkFormat": "kein gültiger Youtubelink",
    "errors.uniqueWebpages": "der Name der Seite muss eindeutig sein",



    "login.usernamerequired": "Benutzername fehlt",
    "login.passwordrequired": "Passwort is required",
    "login.username": "Benutzername",
    "login.password": "Passwort",
    "login.signin": "Anmelden",
    "login.wrong": "Falsche Anmeldedaten",

    "savebar.save": "Speichern",
    "savebar.copy": "Kopieren",
    "savebar.delete": "Löschen",
    "savebar.dityformnav":
      "Sie schliessen ein Formular ohne Änderungen zu speichern.",
    "savebar.continue": "Weiter",
    "savebar.cancel": "Abbrechen",
    "savebar.dityformnavtitle": "Achtung!",

    "dashboard.title": "Home - Clicla",
    "dashboard.newevent": "Neue Veranstaltung",
    "dashboard.addeventdetails": "Veranstaltung Details",
    "dashboard.sendmailing": "Mailing senden",
    "dashboard.addeventschedule": "Programm",
    "dashboard.makemediapage": "Medienseite erstellen",
    "dashboard.addvenuedetails": "Veranstaltungsort",
    "dashboard.addcateringdetails": "Verpflegung",
    "dashboard.managecoworkers": "Mitarbeiter verwalten",
    "dashboard.importgotowebinar": "GoToWebinar",
    "dashboard.generateinvoices": "Generate invoices",
    "dashboard.budget": "Budget",
    "dashboard.viewstatistics": "Kennziffern",
    "dashboard.viewregistrationlist": "Anmeldeliste",
    "dashboard.allevents": "Alle Veranstaltungen",

    "budget.calculation": "Berechnung",
    "budget.documents": "Dokumente",

    "todos.title": "In bearbeitung",
    "todos.sort": "Sortieren",
    "todos.all": "Alle",
    "todos.closest": "Nächstes",
    "todos.complete": "Erledigen",
    "todos.completed": "Erledigt",
    "todos.open": "Offen",
    "todos.notodosfound": "Er sind keine To-Do's verfügbar",
    "todos.add": "Neue To-Do",

    "eventstagelist.title": "Status Veranstaltung",
    "eventstagelist.edit": "Ändern",

    "news.title": "Neu in der App",

    "faq.title": "FAQ",
    "faq.subtitle": "Die häufigsten Fragen",

    "events.title": "Veranstaltungen - Clicla",
    "events.search": "Suche",
    "events.date": "Datum",
    "events.category": "Kategorie",
    "events.blocker": "keine Parallelveranstaltung",
    "events.edit": "ÄNDERN",
    "events.websitelink": "Vorschau",

    WEBINAR: "Webinar",
    SEMINAR: "Seminar",
    TRAINING: "Training",
    WORKSHOP: "Workshop",
    PRESENTATION: "Vortrag",

    "event.dashboard": "Übersicht",
    "event.details": "Verwaltung",
    "event.activities": "Ablaufplan",
    "event.website": "Onlineauftritt",
    "event.contacts": "Teilnehmermanagement",
    "event.mailtexts": "Mailing",
    "event.chat": "Chat",
    "event.accounting": "Budget",
    "event.statistics": "Kennziffern",
    "event.media": "Media",

    "eventdashboard.adddetails": "Details hinzufügen",
    "eventdashboard.sessions": "Ablaufplan",
    "eventdashboard.location": "Veranstaltungsort",
    "eventdashboard.catering": "Catering",
    "eventdashboard.contacts": "Kontakte",
    "eventdashboard.registrations": "Anmeldungen",
    "eventdashboard.accounting": "Budget",
    "eventdashboard.sendmailing": "Mailing senden",
    "eventdashboard.statistics": "Kennziffern",
    "eventdashboard.mediapages": "Medienseite einfügen",
    "eventdashboard.view": "Vorschau",
    "eventdashboard.quickinfo": "Steckbrief Veranstaltung",
    "eventdashboard.eventwith": "Veranstaltung mit",
    "eventdashboard.partner": "Partner",
    "eventdashboard.blocker": "Keine Parallelveranstaltung",
    "eventdashboard.bill": "Rechnung",
    "eventdashboard.venue": "Raumbuchung",
    "eventdashboard.educationtime": "Ausbildungszeit",
    "eventdashboard.category": "Kategorie",
    "eventdashboard.type": "Typ",
    "eventdashboard.notes": "Notizen",
    "eventdashboard.eventstage": "Status Veranstaltung",
    "eventdashboard.team": "Team",
    "eventdashboard.participants": "Teilnehmer",

    "sharednotes.title": "Anmerkungen",
    "sharednotes.addnote": "Neue Anmerkung",
    "sharednotes.nonotesfound": "Er sind keine Anmerkungen verfügbar",

    "eventdetails.eventdetails": "Beschreibung",
    "eventdetails.partner": "Partner Info",
    "eventdetails.catering": "Catering",
    "eventdetails.venue": "Raumbuchung",
    "eventdetails.relations": "Veranstaltungsreihe",
    "eventdetails.education": "Weiterbildung",
    "eventdetails.location": "Veranstaltungsort",
    "eventdetails.registrationsettings": "Registrierungs-Einstellungen",

    "eventdetailstab.title": "Titel der Veranstaltung",
    "eventdetailstab.description": "Beschreibung",
    "eventdetailstab.partner": "Partner",
    "eventdetailstab.partnerno": "Kein Partner",
    "eventdetailstab.partneryes": "Partner",
    "eventdetailstab.catering": "Catering",
    "eventdetailstab.cateringno": "Kein Catering",
    "eventdetailstab.cateringyes": "Catering",
    "eventdetailstab.apellaonly": "Nür Apella Makler",
    "eventdetailstab.apellaonlyno": "Nein",
    "eventdetailstab.apellaonlyyes": "Ja",
    "eventdetailstab.location": "Veranstaltungsort",
    "eventdetailstab.locationno": "Nein",
    "eventdetailstab.locationyes": "Ja",
    "eventdetailstab.start": "Veranstaltungsbeginn",
    "eventdetailstab.end": "Veranstaltungsende",
    "eventdetailstab.blocker": "Parallelveranstaltung erlaubt",
    "eventdetailstab.blockerno": "Ja",
    "eventdetailstab.blockeryes": "Nein",
    "eventdetailstab.repeating": "Wiederholung",
    "eventdetailstab.type": "Typ",
    "eventdetailstab.category": "Kategorie",
    "eventdetailstab.educationtime": "Weiterbildung",
    "eventdetailstab.educationtimeno": "Ohne",
    "eventdetailstab.educationtimeyes": "Mit",
    "eventdetailstab.educationtimehours": "Stunden",
    "eventdetailstab.bill": "Rechnungen erstellen",
    "eventdetailstab.billno": "Nein",
    "eventdetailstab.billyes": "Ja",
    "eventdetailstab.makler34C": "§34c",
    "eventdetailstab.makler34D": "§34d",
    "eventdetailstab.makler34I": "§34i",
    "eventdetailstab.makler34F": "§34f",
    "eventdetailstab.perspectivum": "Perspectivum",
    "eventdetailstab.perspectivumno": "Ohne",
    "eventdetailstab.perspectivumyes": "Mit",
    "eventdetailstab.perspectivumurl": "Perspectivum URL",
    "eventdetailstab.gotowebinar": "GoToWebinar",
    "eventdetailstab.gotowebinarno": "Ohne",
    "eventdetailstab.gotowebinaryes": "Mit",
    "eventdetailstab.gotowebinarurl": "GoToWebinar URL",
    "eventdetailstab.tags": "Tags",
    "eventdetailstab.venue": "Raumbuchung",
    "eventdetailstab.venueyes": "Mit Raumbuchung",
    "eventdetailstab.venueno": "Online",
    "eventdetailstab.errors.startdatebeforeenddate":
      "Veranstaltungsende kann nicht vor Veranstaltungsbeginn liegen",

    "registrationsettingstab.beginregistration": "Beginndatum Registrierungen",
    "registrationsettingstab.endregistration": "Enddatum Registrierungen",
    "registrationsettingstab.password": "Registrierungscode",
    "registrationsettingstab.maxregistrations":
      "Maximale Anzahl an Registrierungen",
    "registrationsettingstab.registrationapproval":
      "Genehmigung der Anmeldung",
    "registrationsettingstab.registrationapprovalno":
      "Automatische Genehmigung",
    "registrationsettingstab.registrationapprovalyes": "Manuelle Genehmigung",
    "registrationsettingstab.attendancecheck": "Abwesenheit bestätigen",
    "registrationsettingstab.attendancecheckyes": "Ja",
    "registrationsettingstab.attendancecheckno": "Nein",
    "registrationsettingstab.waitinglist": "Warteliste",
    "registrationsettingstab.waitinglistyes": "Ja",
    "registrationsettingstab.waitinglistno": "Nein",
    "registrationsettingstab.errors.startdatebeforeenddate":
      "Registrierungsende kann nicht vor Registrierungsbeginn liegen",

    "cateringtab.ordered": "Bestellt",
    "cateringtab.orderedyes": "Bestellt",
    "cateringtab.orderedno": "Nicht bestellt",
    "cateringtab.done": "Fertig",
    "cateringtab.doneyes": "Ja",
    "cateringtab.doneno": "Nein",
    "cateringtab.info": "Catering Info",
    "cateringtab.orderform": "Bestellformular",

    "partnertab.add": "Neuer Partner",
    "partnertab.empty": "Keine Partner ausgewählt",

    "partnerform.name": "Name",
    "partnerform.email": "E-Mail",
    "partnerform.logo": "Logo",
    "partnerform.tel": "Telefon",
    "partnerform.otherinfo": "Weitere Informationen",

    "locationtab.add": "Neuer Veranstaltungsort",
    "locationtab.empty": "Keine Veranstaltungsort ausgewählt",

    "locationform.title": "Name",
    "locationform.street": "Strasse",
    "locationform.street2": "Strasse 2",
    "locationform.postalcode": "PLZ",
    "locationform.city": "Ort",
    "locationform.country": "Land",
    "locationform.description": "Beschreibung",

    "venuetab.ordered": "Gebucht",
    "venuetab.orderedyes": "Bestellt",
    "venuetab.orderedno": "Nicht bestellt",
    "venuetab.tech": "Technik",
    "venuetab.techno": "Nein",
    "venuetab.techyes": "Ja",
    "venuetab.done": "Fertig",
    "venuetab.doneno": "Nein",
    "venuetab.doneyes": "Ja",
    "venuetab.locationinfo": "Veranstaltungsort info",
    "venuetab.offer": "Angebot",

    "uploadcontrol.upload": "Hochladen",
    "uploadcontrol.currentimage": "Aktuell",

    "media.delete": "Entfernen",
    "media.confirm": "Bestätigen",
    "media.cancel": "kündigen",
    "media.alertMultiRemoveTitel": "Achtung",
    "media.alertMultiRemoveDescription":
      "Sie sind dabei, mehrere Dateien zu löschen. <br/>Diese Aktion ist endgültig. Sind Sie sicher?",
    "media.alertRemoveTitel": "Achtung",
    "media.alertRemoveDescription":
      "Sie sind im Begriff, eine Datei zu löschen. <br/>Diese Aktion ist endgültig. Sind Sie sicher?",
    "media.youtubeVideo": "Youtube video",
    "media.photograph": "Bild",
    "media.file": "Dokument",

    "youtubeWindow.title": "Youtubevideo hochladen",
    "youtubeWindow.youtubeLink": "Youtube URL",
    "youtubeWindow.thumbnail": "Vorschaubild",

    "sessions.addRegistrations": "Anmeldung hinzufügen",
    "sessions.removeSelected": "Ausgewählte entfernen",
    "sessions.addsession": "Aktivität hinzüfugen",
    "sessions.addmodule": "Zeitgruppe hinzüfugen",
    "sessions.removeSelectedTitle": "Anmeldung(en) entfernen",
    "sessions.removeSelectedDescription":
      "Sind Sie sicher, dass Sie die Anmeldung(en) aus der ausgewählten Aktivität entfernen möchten?",

    "sessions.selectRegistrations": "Anmeldungen auswählen",
    "sessions.filterBy": "Filtern nach:",

    "moduleform.title": "Zeitgruppe Name",
    "moduleform.description": "Kurzbeschreibung",
    "moduleform.price": "Preis",
    "moduleform.maxregistrations": "Maximale Anzahl Registrierungen",
    "moduleform.shownonregistration": "Auf der Registrierungsseite anzeigen",
    "moduleform.shownonregistrationno": "Nein",
    "moduleform.shownonregistrationyes": "Ja",
    "moduleform.multiselect": "Mehrere Aktivitäten können selektiert werden",
    "moduleform.multiselectno": "Nein",
    "moduleform.multiselectyes": "Ja",

    "sessionform.title": "Aktivität Name",
    "sessionform.shortdescription": "Kurzbeschreibung",
    "sessionform.description": "Beschreibung",
    "sessionform.price": "Preis",
    "sessionform.startdate": "Anfangsdatum",
    "sessionform.enddate": "Enddatum",
    "sessionform.location": "Veranstaltungsort",
    "sessionform.speakers": "Trainers",
    "sessionform.maxregistrations": "Maximale Anzahl Registrierungen",
    "sessionform.shownonregistration": "Auf der Registrierungsseite anzeigen",
    "sessionform.shownonregistrationno": "Nein",
    "sessionform.shownonregistrationyes": "Ja",
    "sessionform.start": "Anfangsdatum",
    "sessionform.end": "Enddatum",
    "sessionform.allowedregistrations":
      "Sie können sich für diese Aktivität registrieren",
    "sessionform.allRegistrationsAllowed": "Teilnahme-Reservierung notwendig",
    "sessionform.scannable": "Für Scannen freigeben",

    //zugang nur mit Voranmeldung
    "sessionform.allowedregistrationsno": "Nein",
    "sessionform.allowedregistrationsyes": "Ja",
    "sessionform.educationTimeTitle": "Weiterbildungszeiten",
    "sessionform.educationTime": "Zertifizierbare Weiterbildungszeit",
    "sessionform.educationTimeIDD": "Weiterbildungszeit (IDD)",
    "sessionform.educationTimeMABV": "Weiterbildungszeit (MABV)",
    "sessionform.scanner": "Check-in (Teilnahme)",
    

    "attributes.title": "Tags und Kategorien",
    "attributes.tags": "Tags",
    "attributes.categories": "Kategorien",

    "tagstab.empty": "Keine tags",
    "tagstab.add": "Neue Tag",

    "categoriestab.empty": "Keine Kategorien",
    "categoriestab.add": "Neue Kategorie",

    "contacts.nofields": "Keine Einträge",
    "contacts.contacts": "Einladung",
    "contacts.registrations": "Anmeldungen",
    "contacts.adminapproval": "Registrierungsanfrage",
    "contacts.adminapprovalrejected": "Registrierungsanfrage abgelehnt",
    "contacts.waitinglist": "Warteliste",
    "contacts.editcontacttitle": "Teilnehmer editieren",
    "contacts.newcontacttitle": "Teilnehmer hinzufügen",
    "contacts.add": "Teilnehmer hinzufügen",
    "contacts.delete": "Löschen",
    "contacts.edit": "Editieren",
    "contacts.attendance": "Teilnahmen",
    "contacts.search": "Suche",
    "contacts.activities": "Aktivitäten",
    "contacts.attendanceCheck": "Teilnahme",
    "contacts.activityCheck": "Aktivität",
    "contacts.export": "Daten exportieren",
    "contacts.registration": "Anmeldung",
    "contacts.resendEmail": "Bestätigungsmail erneut senden",
    "contacts.acceptFromWaitinglist": "von der Warteliste akzeptieren",
    "contacts.approveAll": "Alle Anmeldungen zustimmen",
    "contacts.adminApprovalGranted": "der Anmeldung zustimmen",
    "contacts.adminApprovalRejected": "der Anmeldung ablehnen",
    "contacts.confirm": "Bestätigen",
    "contacts.cancel": "Abbrechen",
    "contacts.adminNote": "Notizen",
    "contacts.confirmDeleteTitle": "Anmeldung löschen?",
    "contacts.confirmDeleteMessage": "Möchten Sie diese Anmeldung löschen?",
    "contacts.confirmAcceptFromWaitinglistTitle":
      "von der Warteliste akzeptieren?",
    "contacts.confirmAcceptFromWaitinglistMessage":
      "Möchten Sie diese Person zu den Anmeldungen verschieben?",
    "contacts.confirmResendTitle": "Bestätigungsmail erneut senden?",
    "contacts.confirmResendMessage":
      "möchten Sie die Bestätigungsmail erneut versenden?",
    "contacts.confirmAdminApprovalGrantedTitle":
      "die Registrierung genehmigen?",
    "contacts.confirmAdminApprovalGrantedMessage":
      "Möchten Sie die Registrierung genehmigen?",
    "contacts.confirmAdminApprovalRejectedTitle": "die Registrierung ablehnen?",
    "contacts.confirmAdminApprovalRejectedMessage":
      "Möchten Sie die Registrierung ablehnen?",
    "contacts.flagNote": "FLAGNOTE",
    "contacts.flagEdit": "Markierung editieren",
    "contact.flagNoteLabel": "Notizen",
    "contact.flagNoteColor": "Farbe",
    "contact.flagNoFlag": "Keine Markierung",

    "attendance.activity": "Aktivität",
    "attendance.registration": "Anmeldung",
    "attendance.type": "Typ",
    "attendance.date": "Datum",
    "attendance.add": "Teilnahme hinzufügen",
    "attendance.typecheckout": "Auschecken",
    "attendance.typecheckin": "Einchecken",
    "attendance.notCheckedIn": "nicht eingecheckt",
    "attendance.checkedIn": "eingecheckt",
    "attendance.checkedOut": "ausgecheckt",
    "attendance.delete": "Löschen",
    "attendance.yes": "Ja",
    "attendance.all": "Alle",
    "attendance.no": "Nein",
    "attendance.filterdoubles": "Doppelte ausblenden",
    "attendance.module": "Zeitgruppe",

    "tagform.title": "Name",

    "categoryform.title": "Name",

    "statistics.title": "Kennzifern",

    "calendar.title": "Kalender",
    "calendar.today": "Heute",
    "calendar.viewEvent": "Veranstaltung ansehen",
    "calendar.editEvent": "Veranstaltung editieren",

    "usermanagement.logins": "Benutzer",
    "usermanagement.contexts": "Kontext",
    "usermanagement.roles": "Rolle",
    "usermanagement.titleMSADMIN": "Benutzer, Rolle und Kontext",
    "usermanagement.title": "Benutzer und Rolle",

    "logins.id": "ID",
    "logins.title": "Benutzerrollen",
    "logins.firstname": "Vorname",
    "logins.lastname": "Nachname",
    "logins.username": "Benutzername",
    "logins.role": "Rolle",
    "logins.edit": "Editieren",
    "logins.delete": "Löschen",
    "logins.add": "Neuen Benutzer anlegen",
    "logins.search": "Suche",
    "logins.save": "Speichern",
    "logins.cancel": "Abbrechen",
    "logins.context": "Kontext",
    "logins.password": "Kennwort",
    "logins.email": "E-Mail",
    "logins.editlogintitle": "Benutzer editieren",
    "logins.newlogintitle": "Neuen Benutzer anlegen",
    "logins.addAccess": "Benutzerzugang hinzufügen",
    "logins.access": "Benutzerzugang",
    "logins.event": "Veranstaltung",
    "logins.activity": "Aktivität",
    "logins.block": "Blok",
    "logins.editloginaccesstitle": "Benutzerzugang editieren",
    "logins.newloginaccesstitle": "Neuen Benutzerzugang anlegen",
    "logins.roleChangeSave": "Bitte speichern Sie das Login, bevor Sie Benutzerzugangen hinzufügen",

    "logins.access.nothingSelectedTitle": "Keine Aktivität ausgewählt",
    "logins.access.nothingSelectedDescription": "Bitte wählen Sie eine Aktivität aus, bevor Sie weitermachen",
    "logins.access.blockFilter": "Nach Zeitgruppe filtern:",

    "logins.access.removeConfirm": "Aktivität entfernen",
    "logins.access.removeConfirmDescription": "Sind Sie sicher, dass Sie die Aktivität aus der Anmeldung entfernen wollen?",



    "roles.id": "ID",
    "roles.title": "Benutzerrollen",
    "roles.name": "Name",
    "roles.edit": "Editieren",
    "roles.delete": "Löschen",
    "roles.add": "Neuen Benutzerrolle anlegen",
    "roles.search": "Suche",
    "roles.save": "Speichern",
    "roles.cancel": "Abbrechen",
    "roles.context": "Kontext",
    "roles.editroletitle": "Benutzerrolle editieren",
    "roles.newroletitle": "Neuen Benutzerrolle anlegen",
    "roles.event": "Verantstaltung editieren",
    "roles.eventno": "Nein",
    "roles.eventyes": "Ja",
    "roles.budget": "Verantstaltung Budget editieren",
    "roles.budgetno": "Nein",
    "roles.budgetyes": "Ja",
    "roles.contacts": "Verantstaltung Anmeldungen editieren",
    "roles.contactsno": "Nein",
    "roles.contactsyes": "Ja",
    "roles.scanner": "Verantstaltung Scanner",
    "roles.scannerno": "Nein",
    "roles.scanneryes": "Ja",

    "contexts.id": "ID",
    "contexts.name": "Name",
    "contexts.edit": "Editieren",
    "contexts.delete": "Löschen",
    "contexts.add": "Neuen Kontext anlegen",
    "contexts.search": "Suche",
    "contexts.save": "Speichern",
    "contexts.cancel": "Abbrechen",
    "contexts.contactemail": "E-Mail",
    "contexts.contactphone": "Telefon",
    "contexts.invoicestreet": "Strasse",
    "contexts.invoicehousenumber": "Hausnr.",
    "contexts.invoicepostalcode": "Postleitzahl",
    "contexts.invoicecity": "Ort",
    "contexts.invoicecountry": "Land",
    "contexts.editcontexttitle": "Kontext editieren",
    "contexts.newcontexttitle": "Neuen Kontext anlegen",

    "website.add": "Seite hinzufügen",
    "website.title": "Name der Seite",

    "website.widgettype.registrationform": "Registrierungsformular",
    "website.widgettype.title": "Titel",
    "website.widgettype.subtitle": "Untertitel",
    "website.widgettype.paragraph": "Textbereich",
    "website.widgettype.section": "Abschnitt",
    "website.widgettype.image": "Bild",
    "website.widgettype.button": "Button",
    "website.widgettype.content": "Content",
    "website.widgettype.iconlist": "Ikonenliste",
    "website.widgettype.slider": "Slider",
    "website.widgettype.video": "Video",

    "website.settings": "Einstellungen",
    "website.settings.title": "Webseite-Einstellungen",
    "website.settings.lastItemTitle": "Kann nicht entfernt werden",
    "website.settings.lastItemMessage": "Sie benötigen mindestens eine Seite",
    "website.settings.tab.pagesettings": "Seiteneinstellungen",
    "website.settings.tab.fonts": "Schriftarten",

    "website.settings.tab.fonts.primary": "Primär",
    "website.settings.tab.fonts.secondary": "Sekundär",
    "website.settings.tab.fonts.accent": "Akzentuierung",

    "website.webpage.settings": "Seite Einstellungen",

    "website.widget.changes": "Es gibt Änderungen",
    "website.widget.changesDescription":
      "Sind Sie sicher, dass Sie fortfahren wollen, ohne zu speichern? Alle Änderungen gehen dann verloren.",

    "registrationform.title": "Titel",
    "registrationform.text": "Text",
    "registrationform.divider": "Lineal ________",
    "registrationform.textfield": "Textfeld",
    "registrationform.emailfield": "E-Mail-Feld",
    "registrationform.telephonefield": "Telefonfeld",
    "registrationform.countryfield": "Länderbereich",
    "registrationform.textarea": "mehrzeilig Textfeld",
    "registrationform.combobox": "Combobox",
    "registrationform.switch": "Schalter",
    "registrationform.radiobuttons": "Radioknöpfen",
    "registrationform.hidden": "ausgeblendetes Feld",
    "registrationform.datefield": "Datumsfeld",
    "registrationform.birthdatefield": "Geburtsdatenfeld",
    "registrationform.defaultoptions": "Option 1<>Option 2",
    "registrationform.checkbox": "Checkbox",
    "registrationform.terms": "Geschäftsbedingungen",
    


    "registrationform.field.title": "Beschriftung",
    "registrationform.field.required": "Eingabe verpflichtet",
    "registrationform.field.hidden": "Nicht anwesend",
    "registrationform.field.adminfield": "Nur für Administratoren",
    "registrationform.field.defaultfield": "Standardfeld",
    "registrationform.field.defaultfieldType": "Standardfeldtyp",
    "registrationform.field.type": "Typ",
    "registrationform.defaultfield.lastname":"Nachname",
    "registrationform.defaultfield.firstname":"Vorname",
    "registrationform.defaultfield.maklernr":"Vermittlernr",
    "registrationform.defaultfield.email":"Email",
    "registrationform.defaultfield.cellphonenumber":"Mobil",
    "registrationform.field.options.newOption": "Neues Option",
    "registrationform.field.options": "Optionen",
    "registrationform.field.termstext": "Text der Geschäftsbedingungen",

    "registrationform.field.visibility": "Sichtbarkeit",
    "registrationform.field.visibility.always": "Immer",
    "registrationform.field.visibility.otherField": "Anderes Feld",
    "registrationform.field.visibility.combobox.optionIs": "Wenn Auswahl ist",
    "registrationform.field.visibility.checkbox.whenFieldIs": "Wenn das Kontrollkästchen ist",
    "registrationform.field.visibility.checkbox.checked": "aktiviert",
    "registrationform.field.visibility.checkbox.unchecked": "deaktiviert",

    

    "option.required": "Pflichtfeld",
    "option.title": "Title",
    "option.checkboxlabel": "Bezeichnung",
    "option.content": "Inhalt",

    "scanner.scan": "Scan",
    "scanner.scanTime": "Scanzeit",
    "scanner.removeScan": "Scan entfernen",
    "scanner.name": "Name",
    "scanner.status": "Status",
    "scanner.duration": "Dauer",
    "scanner.from": "Von",
    "scanner.until": "bis",
    "scanner.date": "Date",
    "scanner.sync": "Sync",
    "scanner.syncNo": "Kein Sync",
    "scanner.syncContinue": "Weiter ohne Sync",
    "scanner.alert": "Achtung",
    "scanner.alertSyncOneDescription":
      "Möchten Sie eine Synchronisierung mit dem Server vornehmen? Dadurch werden die Scans auf dem Gerät entfernt.",
    "scanner.alertSyncDescription":
      "Sie sind dabei, den Scanner zu verlassen. Möchten Sie eine Synchronisierung mit dem Server vornehmen?",
    "scanner.noRegistrations": "Keine Anmeldungen gefunden, Scan deaktiviert.",
    "scanner.blockFilter": "Zeitgruppe:",
    "scanner.noBlock": "Kein Zeitgruppe",
    "scanner.selectSession": "Sitzung auswählen",
    "scanner.session": "Sitzung",
    "scanner.module": "Zeitgruppe",
    "scanner.alertScan": "Auswahl scannen",
    "scanner.alertScanDescription":
      "Möchten Sie einen Code scannen oder weiter scannen?",
    "scanner.singleScan": "Ein Scan",
    "scanner.continuousScan": "Mehrfache Scans",
    "scanner.checkedIn": "eingechecked",
    "scanner.checkedOut": "ausgechecked",
    "scanner.invalidQr": "Kein gültiger QR-Code",
    "scanner.personNotFound":
      "Person nicht in der Liste der Anmeldungen gefunden",
    "scanner.syncCompleted": "Die Daten werden gut synchronisiert",
    "scanner.syncCompletedTitle": "Synchronisierung erfolgreich!",
    "scanner.notOnline": "Keine Internetverbindung gefunden",
    "scanner.notOnlineDescription": "Bitte versuchen Sie es erneut mit einer aktiven Internetverbindung",


    "mailings.sendMails": "Mailing abschicken",
    "mailings.editMailTemplate": "Mail-Einstellungen",
    "mailings.templates": "Vorlagen",
    "mailings.chosenTemplate": "Ausgewählte Vorlage",
    "mailings.templateTitle": "Betreff",
    "mailings.templateText": "Text",
    "mailings.templates.select": "Vorlage selektieren",
    "mailings.templates.registration": "Bestätigungsmail",
    "mailings.templates.waitinglist": "Warteliste",
    "mailings.templates.adminapprovalrequired": "Zwischenbestätigung",
    "mailings.templates.adminapprovalgranted": "Genehmigungsmail",
    "mailings.templates.adminapprovalrejected": "Ablehnungsmail",
    "mailings.templates.notattending": "Nicht anwesend",
    "mailings.save": "Speichern",
    "mailings.noSave": "Nicht Speichern",
    "mailings.markers": "Markierungen",
    "mailings.markersDescription":
      "Platzieren Sie diese Markierung im Format {Markierung} im Text, um sie durch ihren Wert zu ändern.",
    "mailings.changesDescription": "Sie haben eine Änderung an einer Mailvorlage vorgenommen, ohne sie zu speichern.",
    "mailings.changes": "Änderungen",

    //Directions
    "directions.top": "oben",
    "directions.right": "rechts",
    "directions.bottom": "unten",
    "directions.left": "links",

    //EVENT WEBSITE
    "event.website.options.type": "Elementtyp",
    "event.website.options.content": "Inhalt",
    "event.website.options.align": "Align",
    "event.website.options.fontSize": "Schriftgröße",
    "event.website.options.textAlign": "Ausrichten",
    "event.website.options.margin": "Margin",
    "event.website.options.padding": "Padding",
    "event.website.options.backgroundColor": "Hintergrundfarbe",
    "event.website.options.columns": "Spalten",
    "event.website.options.src": "Bild",
    "event.website.options.backgroundImageSrc": "Hintergrundbild",
    "event.website.fileSelect": "Bild auswählen",
    "event.website.options.file": "Dokument",
    "event.website.options.fileSelectImage": "Bild selectieren",
    "event.website.options.fileSelectFile": "Dokument selectieren",
    "event.website.options.videoSelect": "Video selectieren",
    "event.website.options.listitems": "Listeneinträge",
    "event.website.options.width": "Breite",
    "event.website.options.registrationform.title": "Platzhalter für das Registrierungsformular: Klick auf den Bleistift rechts um anzupassen.",
    "event.website.options.srcs": "Bilder",
    "event.website.notSupportedYet": "Slider in Abschnitten werden noch nicht unterstützt!",

    
  },
  en: {
    notfound: "The requested website does not exist or has not yet been published.",

    "login.usernamerequired": "Username is required",
    "login.passwordrequired": "Password is required",
    "login.username": "Username",
    "login.password": "Password",
    "login.signin": "Sign in now",

    "dashboard.title": "Home - Clicla",
    "dashboard.newevent": "Create new event",
    "dashboard.addeventdetails": "Add event details",
    "dashboard.sendmailing": "Send mailing",
    "dashboard.addeventschedule": "Add event schedule",
    "dashboard.makemediapage": "Make media page",
    "dashboard.addvenuedetails": "Add venue details",
    "dashboard.addcateringdetails": "Add catering details",
    "dashboard.managecoworkers": "Manage co-workers",
    "dashboard.importgotowebinar": "Import go to webinar",
    "dashboard.generateinvoices": "Generate invoices",
    "dashboard.budget": "Budget",
    "dashboard.viewstatistics": "View statistics",
    "dashboard.viewregistrationlist": "View registrationlist",
    "dashboard.allevents": "All events",

    "todos.title": "In bearbeitung",
    "todos.all": "All",
    "todos.closest": "Closest",
    "todos.complete": "Complete",
    "todos.completed": "Completed",
    "todos.open": "Open",
    "todos.notodosfound": "There are no to-dos available",
    "todos.add": "New to-do",

    "eventstagelist.title": "Event status",
    "eventstagelist.edit": "Edit",

    "news.title": "New in the app",

    "faq.title": "FAQ",
    "faq.subtitle": "The most important questions",

    "events.title": "Events - Clicla",
    "events.search": "Search",
    "events.date": "Date",
    "events.category": "Category",
    "events.blocker": "Blocker",
    "events.edit": "ÄNDERN",
    "events.websitelink": "View website",

    WEBINAR: "Webinar",
    SEMINAR: "Seminar",
    TRAINING: "Training",
    WORKSHOP: "Workshop",
    PRESENTATION: "Presentation",

    "event.dashboard": "Event dashboard",
    "event.details": "Event details",
    "event.sessions": "Agenda",
    "event.website": "Website",
    "event.contacts": "Contacts",
    "event.mailtexts": "Mailtexts",
    "event.chat": "Chat",
    "event.accounting": "Accounting",
    "event.statistics": "Statistics",
    "event.media": "Media",

    "eventdashboard.adddetails": "Add eventdetails",
    "eventdashboard.sessions": "Planning",
    "eventdashboard.location": "Location",
    "eventdashboard.catering": "Catering",
    "eventdashboard.contacts": "Contacts",
    "eventdashboard.registrations": "Registrations",
    "eventdashboard.accounting": "Budget",
    "eventdashboard.sendmailing": "Send a mailing",
    "eventdashboard.statistics": "Statistics",
    "eventdashboard.mediapages": "Add a mediapage",
    "eventdashboard.view": "View",
    "eventdashboard.quickinfo": "Quick info",
    "eventdashboard.eventwith": "Event with",
    "eventdashboard.partner": "Partner",
    "eventdashboard.blocker": "Blocker",
    "eventdashboard.bill": "Bill",
    "eventdashboard.expectedbudget": "Expected budget",
    "eventdashboard.educationtime": "Education time",
    "eventdashboard.category": "Category",
    "eventdashboard.type": "Type",
    "eventdashboard.notes": "Notes",
    "eventdashboard.eventstage": "Event stage",

    "sharednotes.title": "Shared notes",
    "sharednotes.addnote": "Add note",
    "sharednotes.nonotesfound": "There are no notes available",
  },

  nl: {
    notfound: "De opgevraagde website bestaat niet of is nog niet gepubliceerd.",
  }
};

const language: keyof IFETranslations = sessionStorage.getItem("lang") || "de";
export function setLanguage(val: string) {
  sessionStorage.setItem("lang", val);
}
export function getLanguage() {
  return language;
}
export function getTranslations() {
  return translations[language];
}
export function getGridTranslations() {
  return deDE.components.MuiDataGrid.defaultProps.localeText;
}

export function getWeekDays(): string[] {
  const languageTranslations = translations[language];
  if (!languageTranslations) {
    console.log("translation missing");
    return [];
  }

  const daysTranslations: Record<string, string> = {};

  // Extract the day translations from the translations object
  Object.keys(languageTranslations).forEach((key) => {
    if (key.startsWith("day.")) {
      daysTranslations[key] = languageTranslations[key];
    }
  });

  const orderedDays = [
    "day.sunday",
    "day.monday",
    "day.tuesday",
    "day.wednesday",
    "day.thursday",
    "day.friday",
    "day.saturday",
  ].map((dayKey) => daysTranslations[dayKey]);

  return orderedDays;
}

export function getMonths(): string[] {
  const languageTranslations = translations[language];
  if (!languageTranslations) {
    console.log("Translation missing for language:", language);
    return [];
  }

  const monthTranslations: Record<string, string> = {};

  // Extract the month translations from the translations object
  Object.keys(languageTranslations).forEach((key) => {
    if (key.startsWith("month.")) {
      monthTranslations[key] = languageTranslations[key];
    }
  });

  const orderedMonths = [
    "month.january",
    "month.february",
    "month.march",
    "month.april",
    "month.may",
    "month.june",
    "month.july",
    "month.august",
    "month.september",
    "month.october",
    "month.november",
    "month.december",
  ].map((monthKey) => monthTranslations[monthKey]);

  return orderedMonths;
}

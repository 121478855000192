import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import { IRecord, ModuleRecord } from "../../../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import * as Yup from "yup";
import { DisplayErrors } from "../../../../components/DisplayErrors";

interface ModuleFormProps {
  module: ModuleRecord;
  onDelete: Function;
  onCopy: Function;
}

function ModuleForm(props: ModuleFormProps) {
  const translations = getTranslations();
  const revalidator = useRevalidator();

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      description: obj.translations[obj.defaultLanguage].description || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
      shownOnRegistration: obj.shownOnRegistration, // show on registrationForm
      maxRegistrations: obj.maxRegistrations || "",
      multiselect: obj.multiselect,
      price: obj.price || "",
      scannable: obj.scannable,
      scanFFA: !obj.scanFFA,
    };
  };

  const getSubmissableData = (obj: IRecord) => {
    return {
      ...obj,
      price: obj.price || null,
      scanFFA: !obj.scanFFA,
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.module),
    validationSchema: Yup.object({
      shownOnRegistration: Yup.boolean(),
      multiselect: Yup.boolean(),
      scanFFA: Yup.boolean(),
      title: Yup.string().required(translations["errors.requiredfield"]),
      description: Yup.string().notRequired(),
      price: Yup.number().positive().notRequired(),
      maxRegistrations: Yup.number().positive().integer().notRequired(),
      scannable: Yup.boolean(),
    }),
    onSubmit: (values) => {
      fetch("/api/blocks/" + props.module.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["moduleform.title"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.title && formik.errors.title)}
                {...formik.getFieldProps("title")}
              ></TextField>
              <DisplayErrors
                name="title"
                touched={Boolean(formik.touched.title)}
                errors={formik.errors.title}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["moduleform.description"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                multiline={true}
                minRows={3}
                size="small"
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                {...formik.getFieldProps("description")}
              ></TextField>
              <DisplayErrors
                name="description"
                touched={Boolean(formik.touched.description)}
                errors={formik.errors.description}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["moduleform.price"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.price && formik.errors.price)}
                {...formik.getFieldProps("price")}
              ></TextField>
              <DisplayErrors
                name="price"
                touched={Boolean(formik.touched.price)}
                errors={formik.errors.price}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>
                {translations["moduleform.maxregistrations"]}
              </Typography>
            </Box>
            <FormControl>
              <TextField
                size="small"
                error={Boolean(
                  formik.touched.maxRegistrations &&
                    formik.errors.maxRegistrations
                )}
                {...formik.getFieldProps("maxRegistrations")}
              ></TextField>
              <DisplayErrors
                name="maxRegistrations"
                touched={Boolean(formik.touched.maxRegistrations)}
                errors={formik.errors.maxRegistrations}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["moduleform.multiselect"]}</Typography>
            </Box>
            <Box className="switchlabel">
              {translations["moduleform.multiselectno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="multiselect"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.multiselect}
                  />
                }
                label={translations["moduleform.multiselectyes"]}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["moduleform.shownonregistration"]}
              </Typography>
            </Box>
            <Box className="switchlabel">
              {translations["moduleform.shownonregistrationno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="shownOnRegistration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.shownOnRegistration}
                  />
                }
                label={translations["moduleform.shownonregistrationyes"]}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["sessionform.allRegistrationsAllowed"]}
              </Typography>
            </Box>
            <Box className="switchlabel">
              {translations["sessionform.allowedregistrationsno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="scanFFA"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.scanFFA}
                  />
                }
                label={translations["sessionform.allowedregistrationsyes"]}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["sessionform.scannable"]}</Typography>
            </Box>
            <Box className="switchlabel">
              {translations["sessionform.allowedregistrationsno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="scannable"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.scannable}
                  />
                }
                label={translations["sessionform.allowedregistrationsyes"]}
              />
            </Box>
          </Box>
        </Box>
        <SaveBar
          formik={formik}
          onCopy={() => {
            props.onCopy(getSubmissableData(formik.values));
          }}
          onDelete={() => {
            props.onDelete();
          }}
        />
      </Box>
    </form>
  );
}

export default ModuleForm;

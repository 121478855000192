import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  getGridTranslations,
  getTranslations,
} from "../../../../translations/Translations";
import {
  Category,
  FieldRecord,
  IRecord,
  SessionRecord,
} from "../../../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import {
  formatDate,
  formatTime,
  isValidDateValue,
  parseDate,
  parseTime,
} from "../../../../helpers/DateFunctions";
import { DatePicker, TimeField } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { DisplayErrors } from "../../../../components/DisplayErrors";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnVisibilityModel,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Registrations from "../contacts/Registrations";
import SelectRegistrationsModal from "./modals/SelectRegistrationsModal";
import { Loading } from "../scanner/components/Loading";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import ImprovedChoiceDialog from "../../../../components/ImprovedDialog";
import NumberField from "../../../../components/Fields/NumberField";

interface SessionFormProps {
  session: SessionRecord;
  categories: Category[];
  onDelete: Function;
  onCopy: Function;
  registrations: IRecord[];
  fields: FieldRecord[];
  onRefresh: Function;
}

type BoolState = {
  [key: string]: boolean;
};

function SessionForm(props: SessionFormProps) {
  const { registrations, fields, session, onRefresh, categories } = props;
  const gridtranslations = getGridTranslations();
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const [modalState, setModalState] = useState<BoolState>({
    selectRegistrationsModal: false,
    showRemoveWarning: false,
  });
  const [sideWindowState, setSideWindowState] = useState<BoolState>({
    registrations: false,
    times: false,
  });

  const toggleSideWindow = (key: string) => {
    setSideWindowState((prev) => {
      const updatedState: Record<string, boolean> = {};

      // Set all keys to false
      Object.keys(prev).forEach((prevKey) => {
        updatedState[prevKey] = false;
      });

      // Set the specified key to its opposite value
      updatedState[key] = !prev[key];

      return updatedState;
    });
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const returnHiddenColumns = () => {
    const excludedTypes = ["divider", "text", "title"];
    return [...fields]
      .filter((field) => !excludedTypes.includes(field.type))
      .reduce((obj, field) => {
        if (field.defaultfield) {
          obj["field" + field.id] = true;
        } else {
          obj["field" + field.id] = false;
        }
        return obj;
      }, {} as { [key: string]: boolean });
  };

  useEffect(() => {
    setColumnVisibilityModel(returnHiddenColumns());
  }, [fields]);

  const handleModalClose = (key: string) => {
    setModalState((prev) => ({ ...prev, [key]: false }));
  };

  useEffect(() => {
    if (modalState.selectRegistrationsModal === false) {
      onRefresh();
    }
  }, [modalState]);

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      shortDescription:
        obj.translations[obj.defaultLanguage].shortDescription || "",
      blockId: obj.blockId,
      description: obj.translations[obj.defaultLanguage].description || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
      shownOnRegistration: obj.shownOnRegistration,
      startdate: parseDate(obj.startdate),
      starttime: parseTime(obj.starttime),
      enddate: parseDate(obj.enddate),
      endtime: parseTime(obj.endtime),
      locationId: obj.locationId,
      allowedRegistrations: obj.allowedRegistrations,
      scanFFA: !obj.scanFFA,
      scannable: obj.scannable,
      //educationTime: obj.educationTime || 0,
      educationTimeIDD: obj.educationTimeIDD || 0,
      educationTimeMABV: obj.educationTimeMABV || 0,
      price: obj.price || "",
      maxRegistrations: obj.maxRegistrations || "",
      categoryIds: obj.categories.map((cat: Category) => cat.id),
    };
  };

  const getSubmissableData = (obj: IRecord) => {
    return {
      ...obj,
      startdate: formatDate(obj.startdate),
      enddate: formatDate(obj.enddate),
      starttime: formatTime(obj.starttime),
      endtime: formatTime(obj.endtime),
      price: obj.price || null,
      scanFFA: !obj.scanFFA,
    };
  };

  const removeSelectedRegistrations = () => {
    if (selectedIds.length > 0) {
      fetch("/api/registrations/removeActivity", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        },
        body: JSON.stringify({
          activityId: session.id,
          registrationIds: selectedIds,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setSelectedIds([]);
            onRefresh();
          }
        });
    }
  };

  const openWarningModal = () => {
    setModalState((prev) => {
      return { ...prev, showRemoveWarning: true };
    });
  };

  const closeWarningModal = () => {
    setModalState((prev) => {
      return { ...prev, showRemoveWarning: false };
    });
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.session),
    validationSchema: Yup.object({
      allowedRegistrations: Yup.boolean(),
      scanFFA: Yup.boolean(),
      scanabble: Yup.boolean(),
      locationId: Yup.number().notRequired().nullable(),
      title: Yup.string().required(translations["errors.requiredfield"]),
      blockId: Yup.number().notRequired().nullable(),
      shortDescription: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
      price: Yup.number().positive().notRequired(),
      maxRegistrations: Yup.number().positive().integer().notRequired(),
      startdate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"]),
      enddate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"])
        .when(["startdate"], ([startdate], schema) =>
          isValidDateValue(startdate)
            ? schema.min(
                startdate,
                translations["sessionform.errors.startdatebeforeenddate"]
              )
            : schema
        ),
      starttime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired(),
      endtime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired()
        .when(
          ["startdate", "enddate", "starttime"],
          ([startdate, enddate, starttime], schema) =>
            isValidDateValue(startdate) &&
            isValidDateValue(enddate) &&
            isValidDateValue(starttime) &&
            formatDate(startdate) === formatDate(enddate)
              ? schema.min(
                  starttime,
                  translations["sessionform.errors.startdatebeforeenddate"]
                )
              : schema
        ),
    }),
    onSubmit: (values) => {
      fetch("/api/activities/" + props.session.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  const colorCategory = (categoryId: number) => {
    if (formik.values.categoryIds.includes(categoryId)) {
      return "primary";
    }
    return "default";
  };

  const filteredColumns = fields
    .filter(
      (field) =>
        field.type !== "divider" &&
        field.type !== "text" &&
        field.type !== "title"
    )
    .map((field) => ({
      field: "field" + field.id,
      headerName: field.translations[field.defaultLanguage].title,
      flex: 1,
      filterable: false,
      //groupable: false,
      //hideable: false,
      pinnable: false,
      valueGetter: (params: GridValueGetterParams<IRecord>) => {
        return params.row.values["field" + field.id];
      },
    }));

  const columns = [...filteredColumns];

  return (
    <>
      <Box display="flex" flexDirection={"column"} width="100%">
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            flex: 1,
          }}
        >
          <Box flex="1" display="flex" flexDirection="row">
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["sessionform.title"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    {...formik.getFieldProps("title")}
                  ></TextField>
                  <DisplayErrors
                    name="title"
                    touched={Boolean(formik.touched.title)}
                    errors={formik.errors.title}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.shortdescription"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    multiline={true}
                    minRows={3}
                    {...formik.getFieldProps("shortDescription")}
                  ></TextField>
                  <DisplayErrors
                    name="shortDescription"
                    touched={Boolean(formik.touched.shortDescription)}
                    errors={formik.errors.shortDescription}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.description"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    multiline={true}
                    minRows={3}
                    {...formik.getFieldProps("description")}
                  ></TextField>
                  <DisplayErrors
                    name="description"
                    touched={Boolean(formik.touched.description)}
                    errors={formik.errors.description}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["sessionform.start"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Box display="flex" flexDirection={"row"} gap={1}>
                    <DatePicker
                      format="dd.MM.yyyy"
                      onAccept={(eventorvalue) => {
                        formik.setFieldValue("startdate", eventorvalue);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "startdate",
                          onChange: (eventorvalue) => {
                            formik.setFieldValue("startdate", eventorvalue);
                          },
                          error: Boolean(
                            formik.touched.startdate && formik.errors.startdate
                          ),
                          onBlur: formik.handleBlur,
                        },
                      }}
                      value={formik.values.startdate}
                    />
                    <TimeField
                      format="HH:mm"
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "starttime",
                          onChange: (eventorvalue) => {
                            formik.setFieldValue("starttime", eventorvalue);
                          },
                          error: Boolean(
                            formik.touched.starttime && formik.errors.starttime
                          ),
                          onBlur: formik.handleBlur,
                        },
                      }}
                      value={formik.values.starttime}
                    />
                  </Box>
                  <DisplayErrors
                    name="startdate"
                    touched={Boolean(formik.touched.startdate)}
                    errors={formik.errors.startdate}
                  />
                  <DisplayErrors
                    name="starttime"
                    touched={Boolean(formik.touched.starttime)}
                    errors={formik.errors.starttime}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["sessionform.end"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Box display="flex" flexDirection={"row"} gap={1}>
                    <DatePicker
                      format="dd.MM.yyyy"
                      onAccept={(eventorvalue) => {
                        formik.setFieldValue("enddate", eventorvalue);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "enddate",
                          onChange: (eventorvalue) => {
                            formik.setFieldValue("enddate", eventorvalue);
                          },
                          error: Boolean(
                            formik.touched.enddate && formik.errors.enddate
                          ),
                          onBlur: formik.handleBlur,
                        },
                      }}
                      value={formik.values.enddate}
                    />
                    <TimeField
                      format="HH:mm"
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "endtime",
                          onChange: (eventorvalue) => {
                            formik.setFieldValue("endtime", eventorvalue);
                          },
                          error: Boolean(
                            formik.touched.endtime && formik.errors.endtime
                          ),
                          onBlur: formik.handleBlur,
                        },
                      }}
                      value={formik.values.endtime}
                    />
                  </Box>
                  <DisplayErrors
                    name="enddate"
                    touched={Boolean(formik.touched.enddate)}
                    errors={formik.errors.enddate}
                  />
                  <DisplayErrors
                    name="endtime"
                    touched={Boolean(formik.touched.endtime)}
                    errors={formik.errors.endtime}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.category"]}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  flexWrap="wrap"
                  gap={1}
                >
                  {categories.map((value, index) => {
                    return (
                      <Chip
                        label={value.translations[value.defaultLanguage].title}
                        key={index}
                        color={colorCategory(value.id)}
                        onClick={() => {
                          if (formik.values.categoryIds.includes(value.id)) {
                            formik.setFieldValue(
                              "categoryIds",
                              formik.values.categoryIds.filter((id: number) => {
                                return id !== value.id;
                              })
                            );
                          } else {
                            formik.setFieldValue("categoryIds", [
                              ...formik.values.categoryIds,
                              value.id,
                            ]);
                          }
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.shownonregistration"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["sessionform.shownonregistrationno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="shownOnRegistration"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.shownOnRegistration}
                      />
                    }
                    label={translations["sessionform.shownonregistrationyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.allowedregistrations"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["sessionform.allowedregistrationsno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="allowedRegistrations"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.allowedRegistrations}
                      />
                    }
                    label={translations["sessionform.allowedregistrationsyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["sessionform.price"]}</Typography>
                </Box>
                <FormControl>
                  <TextField
                    size="small"
                    {...formik.getFieldProps("price")}
                  ></TextField>
                  <DisplayErrors
                    name="price"
                    touched={Boolean(formik.touched.price)}
                    errors={formik.errors.price}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["sessionform.maxregistrations"]}
                  </Typography>
                </Box>
                <FormControl>
                  <TextField
                    size="small"
                    {...formik.getFieldProps("maxRegistrations")}
                  ></TextField>
                  <DisplayErrors
                    name="maxRegistrations"
                    touched={Boolean(formik.touched.maxRegistrations)}
                    errors={formik.errors.maxRegistrations}
                  />
                </FormControl>
              </Box>
              <Box mt={"2rem"}>
                <Typography fontWeight={"bold"} color={"#006e55"}>
                  {translations["sessionform.educationTimeTitle"]}
                </Typography>
              </Box>
              {/*}<Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["sessionform.educationTime"]}
                  </Typography>
                </Box>
                <FormControl>
                  <TextField
                    size="small"
                    {...formik.getFieldProps("educationTime")}
                  ></TextField>
                  <DisplayErrors
                    name="educationTime"
                    touched={Boolean(formik.touched.educationTime)}
                    errors={formik.errors.educationTime}
                  />
                </FormControl>
                  </Box>{*/}

              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["sessionform.educationTimeIDD"]}
                  </Typography>
                </Box>
                <FormControl>
                  <NumberField fieldName="educationTimeIDD" formik={formik} />
                  <DisplayErrors
                    name="educationTimeIDD"
                    touched={Boolean(formik.touched.educationTimeIDD)}
                    errors={formik.errors.educationTimeIDD}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["sessionform.educationTimeMABV"]}
                  </Typography>
                </Box>
                <FormControl>
                  <NumberField fieldName="educationTimeMABV" formik={formik} />
                  <DisplayErrors
                    name="educationTimeMABV"
                    touched={Boolean(formik.touched.educationTimeIDD)}
                    errors={formik.errors.educationTimeIDD}
                  />
                </FormControl>
              </Box>
              <Box mt={"2rem"}>
                <Typography fontWeight={"bold"} color={"#006e55"}>
                  {translations["sessionform.scanner"]}
                </Typography>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.allRegistrationsAllowed"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["sessionform.allowedregistrationsno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="scanFFA"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.scanFFA}
                      />
                    }
                    label={translations["sessionform.allowedregistrationsyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["sessionform.scannable"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["sessionform.allowedregistrationsno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="scannable"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.scannable}
                      />
                    }
                    label={translations["sessionform.allowedregistrationsyes"]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <SaveBar
            formik={formik}
            onCopy={() => {
              props.onCopy(getSubmissableData(formik.values));
            }}
            onDelete={() => {
              props.onDelete();
            }}
          />
        </form>
        {/*sideWindowState.registrations &&*/}

        {/* FIX THIS WHEN ANTHONY IS BACK? */}
        {/*}<Box flex={1} display="flex" flexDirection="column" p={'1rem'} sx={{ borderTop: '5px solid #006e55', minHeight: '250px', maxHeight: '50%' }}>
          <Box mb={'1rem'}>
            <Button variant="contained" onClick={() => { setModalState(prev => ({ ...prev, selectRegistrationsModal: true })) }}>{translations['sessions.addRegistrations']}</Button>
            {selectedIds.length > 0 && <Button variant="contained" sx={{ backgroundColor: '#d74444' }} onClick={() => {
              openWarningModal();
            }}><DeleteIcon />{translations['sessions.removeSelected']}</Button>}
          </Box>
          <Box sx={{ display: 'block', boxSizing: 'border-box', flex: 1, overflow: 'auto' }}>
            {!registrations && !columnVisibilityModel &&
              <Loading />
            }
            {registrations && columnVisibilityModel && (<DataGrid
              rows={registrations}
              columns={[{
                field: "actions",
                type: "actions",
                width: 50,
                getActions: (params: GridRowParams) => [
                  <GridActionsCellItem
                    icon={selectedIds.includes(params.row.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    onClick={() => {
                      setSelectedIds(prev => (
                        prev.includes(params.row.id)
                          ? prev.filter(id => id !== params.row.id)
                          : [...prev, params.row.id]
                      ));
                    }}
                    label={''}
                  />,
                ],
              }, ...columns]}
              localeText={gridtranslations}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            ></DataGrid>)}
          </Box>
        </Box>{*/}
        {/* FIX THIS WHEN ANTHONY IS BACK? */}
      </Box>
      {/*}<Box display="flex" flexDirection="column" p={'1rem'} sx={{ borderLeft: '5px solid #006e55' }}>
        <Button onClick={() => {toggleSideWindow('registrations')}}>Show Anmeldungen</Button>
        <Button onClick={() => {toggleSideWindow('times')}}>Show Times</Button>
        </Box>{*/}
      <ImprovedChoiceDialog
        open={modalState.showRemoveWarning}
        title={translations["sessions.removeSelectedTitle"]}
        description={translations["sessions.removeSelectedDescription"]}
      >
        {() => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                removeSelectedRegistrations();
                closeWarningModal();
              }}
            >
              {translations["confirm"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                closeWarningModal();
              }}
            >
              {translations["cancel"]}
            </Button>
          </Box>
        )}
      </ImprovedChoiceDialog>
      <SelectRegistrationsModal
        sessionId={session.id}
        fields={fields}
        columns={columns}
        localText={gridtranslations}
        open={modalState.selectRegistrationsModal}
        closeModal={() => {
          handleModalClose("selectRegistrationsModal");
        }}
      />
    </>
  );
}

export default SessionForm;

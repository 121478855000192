import React from "react";
import { Box } from "@mui/material";
import { ModuleRecord } from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";

const ModuleInfo = (props: {
  block: ModuleRecord | null | undefined;
  defaultLanguage: string;
}) => {
  const { defaultLanguage, block } = props;
  const translations = getTranslations();

  console.log("block", block);

  if (!block) {
    return null;
  }

  const title = block.translations[defaultLanguage]?.title;
  const shortDescription =
    block.translations[defaultLanguage]?.shortDescription;

  return (
    <Box>
      {title && (
        <Box mb={"1rem"}>
          {translations["scanner.module"]}: {title}
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        {shortDescription && <Box>Beschreibung: {shortDescription}</Box>}
      </Box>
    </Box>
  );
};

export default ModuleInfo;
